import React, { Component } from 'react';
import Header from './Header';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import Cookies from "universal-cookie";
import Footer from "./Footer";
import {FormattedMessage} from "react-intl";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Express extends Component
{
    constructor() {
        super();

        var now = new Date();
        var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
        var day = days[ now.getDay() ];

        // DO & FR, 17-20 Uhr
        var inTimeframe = ("Thursday" === day || "Friday" === day) && (now.getHours() > 16 && now.getHours() < 20);

        this.state = {
            inTimeframe: !inTimeframe,
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
            aboButtonText: "Adresse prüfen",
            addressResult: ""
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);

        this.openCheckout = this.openCheckout.bind(this);

        this.handleFirstname = this.handleFirstname.bind(this);
        this.handleLastname = this.handleLastname.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handlePlz = this.handlePlz.bind(this);
        this.handleCity = this.handleCity.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    openCheckout()
    {
        window.open(this.props.checkout.webUrl);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            console.log("creating new checkout id");
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    console.log("checkout is now invalid");
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            // var filteredProducts = [];
            // unfilteredProducts.forEach(product => {
            //     if(product.tags != null && product.tags.length > 0) {
            //         var tags = product.tags.map(tag => tag.value);
            //         if (!tags.includes("deactivated")) {
            //             filteredProducts.push(product);
            //         }
            //     }
            // });
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            var animating = false;

            var addToCartBtn = $(this).find('.add-to-cart');

            if(!animating) {
                //animate if not already animating
                animating =  true;
                // resetCustomization(addToCartBtn);

                addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                    //draw the check icon
                    'stroke-dashoffset':0
                }, 300, function(){
                    setTimeout(function(){
                        // updateCart();
                        addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                            //wait for the end of the transition to reset the check icon
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        });

                        if( $('.no-csstransitions').length > 0 ) {
                            // check if browser doesn't support css transitions
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        }
                    }, 600);
                });
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
        const checkoutId = this.state.checkout.id

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleFirstname(event)
    {
        this.setState({firstName: event.target.value});
    }

    handleLastname(event)
    {
        this.setState({lastName: event.target.value});
    }

    handleAddress(event)
    {
        this.setState({address: event.target.value});
    }

    handlePlz(event)
    {
        this.setState({plz: event.target.value});
    }

    handleCity(event)
    {
        this.setState({city: event.target.value});
    }

    handlePhone(event)
    {
        this.setState({phone: event.target.value});
    }

    async handleSubmit()
    {
        if(
            // this.state.firstName != null &&
            // this.state.lastName != null &&
            this.state.address != null &&
            this.state.plz != null &&
            this.state.city != null &&
            // this.state.firstName != '' &&
            // this.state.lastName != '' &&
            this.state.address != '' &&
            this.state.plz != '' &&
            this.state.city != '')
        {
            await this.setState({aboButtonText: '...'});

            await this.setState({addressResult: "Die Adresse ist leider nicht belieferbar mit Expresszustellung. Versuche es mit einer normalen Bestellung."});

            await this.setState({aboButtonText: 'Addresse überprüfen'});

            // var stripe = window.Stripe(
            //     'pk_live_g7T4M8nNeH1MRX4ygXMjKIue',
            //     {
            //         // betas: ['checkout_beta_4']
            //     }
            // );

            // Make a request for a user with a given ID
            // var sessionId = await axios.get('https://stripe2.vatia.store/stripe/brauwolf-abo', {
            //     params: {
            //         firstName: this.state.firstName,
            //         lastName: this.state.lastName,
            //         address: this.state.address,
            //         plz: this.state.plz,
            //         city: this.state.city,
            //         phone: this.state.phone
            //     }
            // })
            //     .then(function (response) {
            //         // handle success
            //         console.log(response);
            //         return response.data;
            //     })
            //     .catch(function (error) {
            //         // handle error
            //         console.log(error);
            //     })
            //     .finally(function () {
            //         // always executed
            //     });
            //
            // stripe.redirectToCheckout({
            //     sessionId: sessionId
            // }).then(function (result) {
            //     // If `redirectToCheckout` fails due to a browser or network
            //     // error, display the localized error message to your customer
            //     // using `result.error.message`.
            // });
        }
    }

    render()
    {
        let content = (
        <div className="padding-top-2x text-center">
            <h1><FormattedMessage id={ 'express.title' } defaultMessage={ 'Express-Lieferung' }/></h1>
            {/*<img style={{align:"center",paddingBottom:40,marginTop:0,marginLeft:"auto",marginRight:"auto",width:"100%",maxWidth:600}} src="/img/cbm_abo3.png"/>*/}
            {/*<p style={{maxWidth:600, align:"center", marginLeft:"auto", marginRight:"auto"}}>*/}
            {/*    <FormattedMessage id={ 'abo.line1' } defaultMessage={ 'Registriere dich für das Dr. Brauwolf Abo und erhalte jeden Monat' }/><br/>*/}
            {/*    <FormattedMessage id={ 'abo.line2' } defaultMessage={ 'das aktuellste Monatsbier (Craft Beer of the Month) deiner Lieblingsbrauerei für 35.- (6-Pack). Das Abo ist jederzeit kündbar. Die Versandliste wird jeweils am 10. des Monats erstellt.' }/>*/}
            {/*</p>*/}
            {/*<StripeProvider apiKey="pk_live_g7T4M8nNeH1MRX4ygXMjKIue">*/}
            {/*<AboForm/>*/}
            {/*</StripeProvider>*/}
        </div>)

        if(this.state.inTimeframe == true)
        {
            content = (
                <div className="padding-top-2x text-center">
                    <h1><FormattedMessage id={ 'express.title' } defaultMessage={ 'Express-Lieferung' }/></h1>
                    <p><FormattedMessage id={ 'express.erfolgreich' } defaultMessage={ 'Expresslieferung ist zurzeit verfügbar. Du kannst jetzt überprüfen ob deine Adresse belieferbar ist.' }/></p>
                    <form onSubmit={e => e.preventDefault()}>
                        {/*<p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}><FormattedMessage id={ 'abo.firstname' } defaultMessage={ 'Vorname'}/></p>*/}
                        {/*<input type="text" name="firstname" onChange={this.handleFirstname} required/><br/>*/}
                        {/*<p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}><FormattedMessage id={ 'abo.lastname' } defaultMessage={ 'Nachname'}/></p>*/}
                        {/*<input type="text" name="lastname" onChange={this.handleLastname} required/><br/>*/}
                        <p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}><FormattedMessage id={ 'abo.address' } defaultMessage={ 'Adresse'}/></p>
                        <input type="text" name="address" onChange={this.handleAddress} required/><br/>
                        <p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}><FormattedMessage id={ 'abo.plz' } defaultMessage={ 'PLZ'}/></p>
                        <input type="text" name="plz" onChange={this.handlePlz} required/><br/>
                        <p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}><FormattedMessage id={ 'abo.city' } defaultMessage={ 'Stadt'}/></p>
                        <input type="text" name="city" onChange={this.handleCity} required/><br/>
                        {/*<p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}><FormattedMessage id={ 'abo.phone' } defaultMessage={ 'Phone'}/></p>*/}
                        {/*<input type="text" name="phone" onChange={this.handlePhone} required/><br/>*/}
                        <p style={{display:"inline-block",width:100,padding:0,margin:10,marginLeft:0}}></p>
                        <button style={{backgroundColor:"#f19800",border:0,borderRadius:4,color:"#fff",padding:6,marginTop:20}} onClick={() => this.handleSubmit()}>
                            { this.state.aboButtonText }
                        </button>
                    </form>
                    <br/>
                    {this.state.addressResult}
                </div>)
        }
        else
        {
            content = (
                <div className="padding-top-2x text-center">
                    <h1><FormattedMessage id={ 'express.title' } defaultMessage={ 'Express-Lieferung' }/></h1>
                    <p>
                        <FormattedMessage id={ 'express.fail' } defaultMessage={ 'Express ist leider momentan nicht verfügbar.'}/><br/><br/>
                        <FormattedMessage id={ 'express.fail' } defaultMessage={ 'Lieferzeiten sind jeweils Donnerstag & Freitag, 17:00-20:00'}/>
                    </p>
                </div>)
        }

        return (
            <div className="App">
                <div className="page-wrapper">
                      <Header
                          products={this.state.products}
                          checkout={this.state.checkout}
                          isCartOpen={this.state.isCartOpen}
                          handleCartClose={this.handleCartClose}
                          updateQuantityInCart={this.updateQuantityInCart}
                          removeLineItemInCart={this.removeLineItemInCart}
                      />
                    <section className="fw-section bg-gray padding-bottom-3x">
                        {content}
                    </section>
                    {/*<ProductDetailTabs*/}
                        {/*product={product}*/}
                    {/*/>*/}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(Express)
