import React, { Component } from 'react';
import Products from './Products';
import Header from './Header';
import Footer from './Footer';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {FormattedMessage} from "react-intl";
const axios = require('axios');
import ReactHtmlParser from 'react-html-parser';

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Home extends Component {

    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
            heroContent: ''
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);

            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }

        // fetch banner
        // axios.get('https://admin.vatia.ch/content/hero/brauwolf')
        //     .then(response => {
        //
        //         var divStyle6 =
        //             {
        //                 fontFamily: 'titling',
        //                 color: '#fff',
        //                 // textShadow: '-0.5px 0 #999, 0 0.5px #999, 0.5px 0 #999, 0 -0.5px #999'
        //             }
        //         var divStyle7 =
        //             {
        //                 color: '#fff',
        //                 backgroundColor: 'black',
        //                 color: '#fff',
        //                 display: 'inline',
        //                 padding: '0.5rem',
        //                 opacity:0.8,
        //                 // -webkitBoxDecorationBreak: 'clone',
        //                 // boxDecorationBreak: 'clone'
        //             }
        //
        //         var divStyle1o =
        //             {
        //                 backgroundImage: 'url('+response.data.img+')',
        //             };
        //
        //         let slideList = response.data.map(i =>
        //         {
        //             var links = i.link.split(',').map(link => {
        //
        //                 if(link.indexOf(";") !== -1)
        //                 {
        //                     var items = link.split(";");
        //
        //                     return (<span><br/><a href={items[0]} className="btn btn-primary btn-with-icon-right waves-effect waves-light scale-up">
        //                     {items[1]}
        //                     <i className="material-icons arrow_forward"></i>
        //                     </a></span>);
        //                 }
        //                 else
        //                 {
        //                     return (<span><br/><a href={link} className="btn btn-primary btn-with-icon-right waves-effect waves-light scale-up">
        //                     <FormattedMessage id={ 'hero.link' } defaultMessage={ 'Zum Angebot' }/>
        //                     <i className="material-icons arrow_forward"></i>
        //                     </a></span>);
        //                 }
        //             })
        //
        //             var img = '';
        //             if(i.type != null)
        //             {
        //                 img = "data:image/"+i.type+";base64,"+i.img;
        //             }
        //             else
        //             {
        //                 img = i.img;
        //             }
        //
        //             return (<div className="slide" style={{backgroundImage: 'url('+img+')'}}>
        //                 <div className="container padding-top-3x">
        //             <span className="h1 space-top from-left" style={divStyle6}>
        //             { i.title }
        //             </span><br/>
        //                     <span className="h2 from-right"><span className="text-thin" style={divStyle7}>
        //                         <FormattedMessage id={ 'hero.product.slogan.'+ i.link.split(',')[0].split(";")[0]} defaultMessage={ReactHtmlParser(i.slogan)}/>
        //             </span>
        //             </span>{links}
        //                 </div>
        //             </div>)
        //         });
        //
        //         var heroContent = (<div className="inner visible-lg ">{slideList}</div>)
        //
        //         this.setState({heroContent});
        //
        //         // get script to bind hero slider
        //         $.getScript('js/scripts.js', function(){
        //             console.log("Load was performed");
        //         })
        //
        //     })
        //     .catch(function (error)
        //     {
        //         console.log(error);
        //     })
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            if(this.id === variantId)
            {
                var animating = false;

                var addToCartBtn = $(this).find('.add-to-cart');

                if(!animating) {
                    //animate if not already animating
                    animating =  true;
                    // resetCustomization(addToCartBtn);
                    addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                        //draw the check icon
                        'stroke-dashoffset':0
                    }, 300, function(){
                        setTimeout(function(){
                            // updateCart();
                            addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                                //wait for the end of the transition to reset the check icon
                                addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                                animating =  false;
                            });

                            if( $('.no-csstransitions').length > 0 ) {
                                // check if browser doesn't support css transitions
                                addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                                animating =  false;
                            }
                        }, 600);
                    });
                }
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
        const checkoutId = this.state.checkout.id;

        // console.log("adding variant in home");
        // let count = this.state.checkout.lineItems.map((line_item) => {return line_item.quantity;}).reduce(function(a,b) {return a+b;},0) + quantity;
        // let discountCode = "";
        // if(count == 1)
        // {
        //     //Removes the applied discount from an existing checkout.
        //     client.checkout.removeDiscount(this.state.checkout.id).then(checkout => {
        //         console.log("removed discount");
        //     });
        // }
        // else if(count == 2)
        // {
        //     discountCode = 'DM7A2CR4DFP4';
        // }
        // else if(count == 3)
        // {
        //     discountCode = 'R86XDV0Z6S68';
        // }
        // else if(count == 4)
        // {
        //     discountCode = 'N7GYCT233BEY';
        // }
        // else if(count == 5)
        // {
        //     discountCode = 'D7JYFRYS6GSS';
        // }
        //
        // if(discountCode != "" && this.state.checkout.discountApplications)
        // {
        //     if (this.state.checkout.discountApplications.length == 0 && count > 1)
        //     {
        //         client.checkout.addDiscount(this.state.checkout.id, discountCode).then(checkout =>
        //         {
        //             console.log("added new discount", discountCode);
        //         });
        //     }
        //     else
        //     {
        //         if(this.state.checkout.discountApplications[0] != null && this.state.checkout.discountApplications[0].code != discountCode)
        //         {
        //             client.checkout.addDiscount(this.state.checkout.id, discountCode).then(checkout =>
        //             {
        //                 console.log("added new discount", discountCode);
        //             });
        //         }
        //     }
        // }

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    render() {

        document.onkeydown = checkKey;

        function checkKey(e) {

            e = e || window.event;

            if (e.keyCode == '38') {
                // up arrow
            }
            else if (e.keyCode == '40') {
                // down arrow
            }
            else if (e.keyCode == '37') {
                // left arrow
                document.querySelector('.owl-prev').click();
            }
            else if (e.keyCode == '39') {
                // right arrow
                document.querySelector('.owl-next').click();
            }

            else if (e.keyCode == '13') {
                document.querySelector('.active').querySelector('.detail').click();
            }

            else if (e.keyCode == '27') {
                document.querySelector('.scroll-to-top-btn').click();
            }

        }

        return (
            <div className="App">
                <Header
                    products={this.state.products}
                    checkout={this.state.checkout}
                    isCartOpen={this.state.isCartOpen}
                    handleCartClose={this.handleCartClose}
                    updateQuantityInCart={this.updateQuantityInCart}
                    removeLineItemInCart={this.removeLineItemInCart}
                />
                <section className="hero-slider" data-loop="true" data-autoplay="true" data-interval="7000">
                    {this.state.heroContent}
                </section>
                <Products
                    products={this.state.products}
                    addVariantToCart={this.addVariantToCart}
                    checkout={this.state.checkout}
                    updateQuantityInCart={this.updateQuantityInCart}
                    client={client}
                />
                <Footer/>
            </div>
        );
    }
}

// export default Home;
export default withRouter(Home)
