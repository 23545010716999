import React, { Component } from 'react';
import slugify from 'slugify';
import {FormattedMessage} from "react-intl";

class ProductDetailNav extends Component {
  render() {

      let product = null;
      let variantImage = null;
      let previous = null;
      let next = null;
      let previousImage = null;
      let nextImage = null;
      let previousUrl = null;
      let nextUrl = null;

      if(this.props.product)
      {
          product = this.props.product;
          variantImage = product.images[0];
          previous = this.props.previous;
          next = this.props.next;
          previousImage = previous.images[0];
          nextImage = next.images[0];

          previousUrl = slugify(previous.title, {
              replacement: '-',    // replace spaces with replacement
              remove: null,        // regex to remove characters
              lower: true          // result in lower case
          });

          nextUrl = slugify(next.title, {
              replacement: '-',    // replace spaces with replacement
              remove: null,        // regex to remove characters
              lower: true          // result in lower case
          });
      }

      var svgStyle =
      {
          backgroundColor: '#999'
      }

    return (
        <section className="fw-section bg-white">
            <a href={previousUrl} className="page-nav page-prev">
        <span className="page-preview">
          {product && previousImage ? <img src={previousImage.src} alt={`${previous.title} product shot`}/> : null}
        </span>
                &mdash; <FormattedMessage id={ 'detail.back' } defaultMessage={ 'Zurück' }/>
            </a>
            <a href={nextUrl} className="page-nav page-next">
        <span className="page-preview">
          {product && nextImage ? <img src={nextImage.src} alt={`${next.title} product shot`}/> : null}
        </span>
                <FormattedMessage id={ 'detail.forward' } defaultMessage={ 'Weiter' }/> &mdash;
            </a>
            <div className="container">

                <div className="product-gallery">
                    <ul className="product-gallery-preview">
                        {/*<li id="preview01">*/}
                            {/*/!*<img src="/img/shop/product-gallery/preview01.jpg" alt="Product"/>*!/*/}
                            {/*{product ? <img src={previousImage.src} alt={`${this.props.product.title} product shot`}/> : null}*/}
                        {/*</li>*/}
                        <li id="preview02" className="current">
                            {product ? <img src={variantImage.src} alt={`${this.props.product.title} product shot`}/> : <svg className={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 835 835"/>}
                        </li>
                        {/*<li id="preview03">*/}
                            {/*/!*<img src="/img/shop/product-gallery/preview03.jpg" alt="Product"/>*!/*/}
                            {/*{product ? <img src={nextImage.src} alt={`${this.props.product.title} product shot`}/> : null}*/}
                        {/*</li>*/}
                    </ul>
                    {/*<ul className="product-gallery-thumblist">*/}
                        {/*<li><a href="#preview01">*/}
                            {/*<img src="/img/shop/product-gallery/thumb01.jpg" alt="Product"/>*/}
                        {/*</a></li>*/}
                        {/*<li className="active"><a href="#preview02">*/}
                            {/*<img src="/img/shop/product-gallery/thumb02.jpg" alt="Product"/>*/}
                        {/*</a></li>*/}
                        {/*<li><a href="#preview03">*/}
                            {/*<img src="/img/shop/product-gallery/thumb03.jpg" alt="Product"/>*/}
                        {/*</a></li>*/}
                    {/*</ul>*/}
                </div>
            </div>
        </section>
    );
  }
}

export default ProductDetailNav;
