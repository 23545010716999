import React, { Component } from 'react';
import Header from './Header';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import ProductDetailNav from "./ProductDetailNav";
import ProductDetailInfo from "./ProductDetailInfo";
// import ProductDetailTabs from "./ProductDetailTabs";
import slugify from 'slugify';
import Cookies from "universal-cookie";
import Footer from "./Footer";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Detail extends Component {

    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);

        this.openCheckout = this.openCheckout.bind(this);
    }

    openCheckout() {
        window.open(this.props.checkout.webUrl);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            console.log("creating new checkout id");
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    console.log("checkout is now invalid");
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            // var filteredProducts = [];
            // unfilteredProducts.forEach(product => {
            //     if(product.tags != null && product.tags.length > 0) {
            //         var tags = product.tags.map(tag => tag.value);
            //         if (!tags.includes("deactivated")) {
            //             filteredProducts.push(product);
            //         }
            //     }
            // });
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            var animating = false;

            var addToCartBtn = $(this).find('.add-to-cart');

            if(!animating) {
                //animate if not already animating
                animating =  true;
                // resetCustomization(addToCartBtn);

                addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                    //draw the check icon
                    'stroke-dashoffset':0
                }, 300, function(){
                    setTimeout(function(){
                        // updateCart();
                        addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                            //wait for the end of the transition to reset the check icon
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        });

                        if( $('.no-csstransitions').length > 0 ) {
                            // check if browser doesn't support css transitions
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        }
                    }, 600);
                });
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
        const checkoutId = this.state.checkout.id

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    render() {

        let product = null;
        let previous = null;
        let next = null;

        let pageId = this.props.match.params.id;
        let products = this.state.products;

        for (var i = 0; i < products.length; i++)
        {
            if(slugify(products[i].title, {
                    replacement: '-',    // replace spaces with replacement
                    remove: null,        // regex to remove characters
                    lower: true          // result in lower case
                }) === pageId)
            {
                product = products[i];

                if(i-1 < 0) {
                    previous = products[products.length - 1];
                }
                else {
                    previous = products[i - 1];
                }

                if(i+1 > products.length - 1)
                {
                    next = products[0];
                }
                else
                {
                    next = products[i+1];
                }

                document.title = "drbrauwolf | " + product.title.toUpperCase();
            }
        }

        document.onkeydown = checkKey;

        function checkKey(e) {

            var previousUrl = slugify(previous.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            var nextUrl = slugify(next.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            e = e || window.event;

            if (e.keyCode == '38') {
                // up arrow
            }
            else if (e.keyCode == '40') {
                // down arrow
            }
            else if (e.keyCode == '37') {
                document.location.href = previousUrl;
            }
            else if (e.keyCode == '39') {
                console.log(nextUrl);
                document.location.href = nextUrl;
            }

            else if (e.keyCode == '13') {
                document.querySelector('.buy').click();
            }

            else if (e.keyCode == '27') {
                document.location.href = "/";
            }

        }

        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);

        var xDown = null;
        var yDown = null;

        function handleTouchStart(evt) {
            xDown = evt.touches[0].clientX;
            yDown = evt.touches[0].clientY;
        };

        function handleTouchMove(evt) {
            if ( ! xDown || ! yDown ) {
                return;
            }

            var previousUrl = slugify(previous.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            var nextUrl = slugify(next.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;

            var xDiff = xDown - xUp;
            var yDiff = yDown - yUp;

            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    /* left swipe */
                    document.location.href = previousUrl;
                } else {
                    document.location.href = nextUrl;
                }
            } else {
                if ( yDiff > 0 ) {
                    /* up swipe */
                } else {
                    /* down swipe */
                }
            }
            /* reset values */
            xDown = null;
            yDown = null;
        };

        var titleStyle = {
            fontFamily: 'titling',
            fontSize: '36px',
            color: '#000'
        };

        return (
            <div className="App">
                <div className="page-wrapper">
                      <Header
                          products={this.state.products}
                          checkout={this.state.checkout}
                          isCartOpen={this.state.isCartOpen}
                          handleCartClose={this.handleCartClose}
                          updateQuantityInCart={this.updateQuantityInCart}
                          removeLineItemInCart={this.removeLineItemInCart}
                      />
                    <section className="fw-section bg-gray padding-bottom-3x">
                        <div className="padding-top-2x text-center">
                            <h1 className="h2 space-bottom-half" style={titleStyle}>Datenschutz</h1>
                            <div className="container">
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1">DATENSCHUTZERKLÄRUNG (DSE)</span>
                                </p>
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1">Dr. Brauwolf GmbH, Räffelstrasse 26, 8045 Zürich (CH) ist Betreiber/in der Website shop.drbrauwolf.ch und der darauf angebotenen Dienste und somit verant- wortlich für die Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten und die Vereinbarkeit der Datenbearbeitung mit dem anwendbaren Datenschutzrecht.</span>
                                </p>
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1">Ihr Vertrauen ist uns wichtig, darum nehmen wir das Thema Datenschutz ernst und achten auf entsprechende Sicherheit. Selbstverständlich beachten wir die gesetzlichen Bestimmungen des Bundesgesetzes über den Datenschutz (DSG), der Verordnung zum Bundesgesetz über den Datenschutz (VDSG), des Fernmeldegesetztes (FMG) und andere gegebenenfalls anwendbare datenschutzrechtliche Bestimmungen des schweizerischen oder EU-Rechts, insbesondere die Datenschutzgrundverordnung (DSGVO).</span>
                                </p>
                                <p><span style={{fontSize: 'large'}} data-mce-mark="1">Damit Sie wissen, welche personenbezogenen Daten wir von Ihnen erheben und für welche Zwecke wir sie verwenden, nehmen Sie bitte die nachstehenden Informationen zur Kenntnis.</span>
                                </p>
                                <div className="page" title="Page 1">
                                    <div className="layoutArea">
                                        <div className="column">
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">1. Aufruf unserer Website</span></strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Beim Besuch unserer Website speichern unsere Server temporär jeden Zugriff in einer Protokolldatei. Folgende technischen Daten werden dabei, wie grundsätzlich bei jeder Verbindung mit einem Webserver, ohne Ihr Zutun erfasst und bis zur automatisierten Löschung nach spätestens 26 Monaten von uns gespeichert:</span>
                                            </p>
                                            <ul style={{listStylePosition: 'inside'}}>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">die IP-Adresse des anfragenden Rechners,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">der Name des Inhabers des IP-Adressbereichs (i.d.R. Ihr Internet-<span
                                                    style={{fontSize:'medium'}}
                                                    data-mce-mark="1">Access-Provider),</span></span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">das Datum und die Uhrzeit des Zugriffs,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">die Website, von der aus der Zugriff erfolgte (Referrer URL) ggf.&nbsp;
                                                    <span style={{fontSize:'medium'}} data-mce-mark="1">mit verwendetem Suchwort,</span></span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">der Name und die URL der abgerufenen Datei,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">den Status-Code (z.B. Fehlermeldung),</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">das Betriebssystem Ihres Rechners,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">der von Ihnen verwendete Browser (Typ, Version und Sprache),</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">das verwendete Übertragungsprotokoll (z.B. HTTP/1.1) und<span
                                                    style={{fontSize:'medium'}} data-mce-mark="1">&nbsp;</span><span
                                                    style={{fontSize:'medium'}} data-mce-mark="1">ggf. Ihr Nutzername aus einer Registrierung/Authentifizierung</span></span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="column">&nbsp;</div>
                                        <div className="column"><span style={{fontSize:'medium'}} data-mce-mark="1">Die Erhebung und Verarbeitung dieser Daten erfolgt zu dem Zweck, die Nutzung unserer Website zu ermöglichen (Verbindungsaufbau), die Systemsicherheit und -stabilität dauerhaft zu gewährleisten und die Optimierung unseres Internetangebots zu ermöglichen sowie zu internen statistischen Zwecken. Hierin besteht unser berechtigtes Interesse an der Datenverarbeitung im Sinne von Art. 6 Abs. 1 lit. f DSGVO.</span>
                                        </div>
                                        <div className="column"><span style={{fontSize:'medium'}}
                                                                      data-mce-mark="1">&nbsp;</span></div>
                                    </div>
                                </div>
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                    data-mce-mark="1"><span data-mce-mark="1"><span data-mce-mark="1">2. Eröffnung eines Kundenkontos</span></span></span></strong></span>
                                </p>
                                <div className="page" title="Page 2">
                                    <div className="layoutArea">
                                        <div className="column">
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Um im Onlineshop Bestellungen zu tätigen, können Sie als Gast bestellen oder ein Kunden-Konto eröffnen. Bei der Registrierung für ein Kunden-Konto erheben wir folgende Daten:</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="layoutArea">
                                        <div className="column">
                                            <ul style={{listStylePosition: 'inside'}}>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">Vor- und Nachname</span></li>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">Postadresse</span></li>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">Geburtsdatum</span></li>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">E-Mail Adresse</span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Passwort</span>
                                                </li>
                                            </ul>
                                            <span style={{fontSize:'medium'}} data-mce-mark="1"><br/></span>
                                            <p><span style={{fontSize:'medium'}}
                                                     data-mce-mark="1">Die Erhebung&nbsp;sch</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">ü</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">tzten direkten Zugang zu seinen bei uns gespeicherten Basisdaten zur Ver- fügung zu stellen. Der Kunde kann darin seine abgeschlossenen und offenen Bestellungen einsehen oder seine persönlichen Daten verwalten bzw. ändern.&nbsp;</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Die Rechtsgrundlage der Verarbeitung der Daten für diesen Zweck liegt in der von Ihnen erteilten Einwilligung nach Art. 6 Abs. 1 lit. a EU-DSGVO.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong>3. Einkauf im Onlineshop</strong></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Wenn Sie in unserem Onlineshop Bestellungen tätigen möchten, benötigen wir für die Abwicklung des Vertrags folgende Daten:</span>
                                            </p>
                                            <ul style={{listStylePosition: 'inside'}}>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">Vor- und Nachname</span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Rechnungsadresse (und falls abweichend Lieferadresse)</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Angaben im Rahmen der Zahlung (abhängig von der gewählten</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">Zahlungsmethode)</span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Login-Daten, d.h. E-Mail-Adresse und Passwort (bei registrierten Kunden)</span>
                                                </li>
                                            </ul>
                                            <span style={{fontSize:'medium'}} data-mce-mark="1"><br/></span>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sofern in dieser Datenschutzerklärung nicht anders festgehalten bzw. Sie dazu nicht gesondert eingewilligt haben, werden wir die vorgenannten Daten nur benutzen, um den Vertrag abzuwickeln, namentlich um Ihre Bestellungen zu bearbeiten, die bestellten Produkte auszuliefern und die korrekte Zahlung sicherzustellen.&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Die Rechtsgrundlage der Datenverarbeitung zu diesem Zweck liegt in der Erfüllung eines Vertrages nach Art. 6 Abs. 1 lit. b EU-DSGVO.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong>4. Weitergabe der Daten an Dritte</strong></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie ausdrücklich eingewilligt haben, hierfür eine gesetzliche Verpflichtung besteht oder dies zur Durchsetzung unserer Rechte, insbesondere zur Durchsetzung von Ansprüchen aus dem Vertragsverhältnis, erforderlich ist.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Darüber hinaus geben wir Ihre Daten an Dritte weiter, soweit dies im Rahmen der Nutzung der Webseite und der Vertragsabwicklung (auch ausserhalb der Webseite</span>seite), namentlich der Verarbeitung Ihrer Buchungen erforderlich ist. Hierzu zählt der jeweilige Transportdienstleister, der mit dem Versand von bestellten Waren betraut wurde. Ein Dienstleister, an den die über die Website erhobenen personenbezogenen Daten weitergegeben werden bzw. der Zugriff darauf hat oder haben kann, ist unser Webhoster Webland AG, Emil Frey-Strasse 85, CH-4142 Münchenstein. Die Webseite wird auf Servern in der Schweiz gehostet. Die Weitergabe der Daten erfolgt zum Zweck der Bereitstellung und Aufrechterhaltung der Funktionalitäten unserer Website. Hierin besteht unser berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f EU-DSGVO.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="page" title="Page 3">
                                    <div className="layoutArea">
                                        <div className="column">
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sofern wir in Vorleistung treten, z.B. bei einem Kauf auf Rechnung, können wir zur Wahrung unserer berechtigten Interessen gegebenenfalls eine Bonitätsauskunft auf der Basis mathematisch-statistischer Verfahren bei einer Auskunft einholen. Hierzu übermitteln wir die zu einer Bonitätsprüfung benötigten Personen- daten an die Auskunft: Intrum AG, Eschenstrasse 12, 8603 Schwerzenbach und verwenden die erhaltenen Informationen über die statistische Wahrscheinlichkeit eines Zahlungsausfalles für eine abgewogene Entscheidung über die Begrün- dung, Durchführung oder Beendigung des Vertragsverhältnisses. Die Bonitätsauskunft kann Wahrscheinlichkeitswerte (Score-Werte) beinhalten, die auf Basis wissenschaftlich anerkannter mathematisch-statistischer Verfahren berechnet wurden und in deren Berechnung unter anderem Adressdaten einfliessen. Ihre schutzwürdigen Interessen werden gemäss den gesetzlichen Bestimmungen berücksichtigt. In den vorangehend beschriebenen Zwecken besteht unser be- rechtigtes Interesse an der Datenverarbeitung im Sinne von Art. 6 Abs. 1 lit. f EU- DSGVO.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Schliesslich leiten wir Ihre Kreditkarteninformationen bei Kreditkartenzahlung auf der Webseite an Ihren Kreditkartenherausgeber sowie an den Kreditkarten-</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Acquirer weiter. Wenn Sie sich für eine Kreditkartenzahlung entscheiden, werden Sie jeweils zur Eingabe aller zwingend notwendigen Informationen gebeten. Die Rechtsgrundlage der Weitergabe der Daten liegt in der Erfüllung eines Vertrages nach Art. 6 Abs. 1 lit. b EU-DSGVO. Betreffend die Bearbeitung Ihrer Kreditkarteninformationen durch diese Dritten bitten wir Sie, auch die Allgemeinen Geschäftsbedingungen sowie die Datenschutzerklärung Ihres Kreditkartenherausgebers zu lesen.</span></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong>5. Übermittlung von Daten ins Ausland</strong></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Wir sind berechtigt, Ihre persönlichen Daten zum Zwecke der in dieser Datenschutzerklärung beschriebenen Datenbearbeitungen auch an dritte Unternehmen (beauftragte Dienstleister) im Ausland zu übertragen. Diese sind im gleichen Umfang wie wir selbst zum Datenschutz verpflichtet. Wenn das Datenschutzniveau in einem Land nicht dem schweizerischen bzw. dem europäischen entspricht, stellen wir vertraglich sicher, dass der Schutz Ihrer personenbezogenen Daten demjenigen in der Schweiz bzw. in der EU jederzeit entspricht.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}
                                                     data-mce-mark="1"><strong>6. Cookies</strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Cookies helfen unter vielen Aspekten, Ihren Besuch auf unserer Website einfacher, angenehmer und sinnvoller zu gestalten. Cookies sind Informationsdateien, die Ihr Webbrowser automatisch auf der Festplatte Ihres Computers speichert, wenn Sie unsere Internetseite besuchen.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1">Wir setzen Cookies beispielsweise ein, um Ihnen die Warenkorb-Funktion über mehrere Seiten hinweg anzubieten und um Ihre Eingaben beim Ausfüllen eines Formulars auf der Website temporär zu speichern, damit Sie die Eingabe beim Aufruf einer anderen Unterseite nicht wiederholen müssen. Cookies werden gegebenenfalls auch eingesetzt, um Sie nach der Registrierung auf der Website als registrierten Benutzer identifizieren zu können, ohne dass Sie sich beim Aufruf einer anderen Unterseite erneut einloggen müssen.</span>
                                </p>
                                <div className="page" title="Page 4">
                                    <div className="layoutArea">
                                        <div className="column"><span style={{fontSize:'medium'}} data-mce-mark="1">Die meisten Internet-Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, wenn Sie ein neues Cookie erhalten. Auf den nachfolgenden Seiten finden Sie Erläuterungen, wie Sie die Verarbeitung von Cookies bei den gängigsten Browsern konfigurieren können:</span>
                                        </div>
                                        <div className="column"><br/>
                                            <ul style={{listStylePosition: 'inside'}}>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Microsofts Windows Internet Explorer</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Microsofts Windows Internet Explorer Mobile</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}}
                                                          data-mce-mark="1">Mozilla Firefox</span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Google Chrome für Desktop</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Google Chrome für Mobile</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Apple Safari für Desktop</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Apple Safari für Mobile</span>
                                                </li>
                                            </ul>
                                            <span style={{fontSize:'medium'}} data-mce-mark="1"><br/></span><span
                                            style={{fontSize:'medium'}} data-mce-mark="1">Die Deaktivierung von Cookies kann dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.</span>
                                        </div>
                                        <div className="column"><br/>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">7. Tracking-Tools </span></strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">a. Allgemeines</span></strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Website nutzen wir den Webanalysedienst von Google Analytics. In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und klei</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">ne Textdateien, die auf Ihrem Computer gespeichert sind („Cookies“), verwendet.&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden an die Server der Anbieter dieser Dienste übertragen, dort ge- speichert und für uns aufbereitet. Zusätzlich zu den unter Ziff. 1 aufgeführten Daten erhalten wir dadurch unter Umständen folgende Informationen:</span></span>
                                            </p>
                                            <ul style={{listStylePosition: 'inside'}}>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Navigationspfad, den ein Besucher auf der Site beschreitet,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Verweildauer auf der Website oder Unterseite,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">die Unterseite, auf welcher die Website verlassen wird,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">das Land, die Region oder die Stadt, von wo ein Zugriff erfolgt,</span>
                                                </li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                    data-mce-mark="1">Endgerät (Typ, Version, Farbtiefe, Auflösung, Breite und Höhe des&nbsp;</span><span
                                                    data-mce-mark="1">Browserfensters) und</span></span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Wiederkehrender oder neuer Besucher</span>
                                                </li>
                                            </ul>
                                            <span style={{fontSize:'medium'}} data-mce-mark="1"><br/></span>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                data-mce-mark="1">Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Website zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte über</span><span
                                                data-mce-mark="1">tragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten.</span></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="page" title="Page 5">
                                    <div className="layoutArea">
                                        <div className="column">
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">b. Google Analytics</span></strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Anbieter</span></span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1"> von Google Analytics ist Google Inc., ein Unternehmen der Holding Gesellschaft Alphabet Inc, mit Sitz in den USA. Vor der Übermittlung der Daten an den Anbieter wird die IP-Adresse durch die Aktivierung der IP-Anonymisierung&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">(„anonymizeIP“) auf dieser Webseite innerhalb der Mitgliedstaaten der Europä</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">ischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte anonymisierte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In diesen Fäl- len stellen wir durch vertragliche Garantien sicher, dass Google Inc. ein ausreichendes Datenschutzniveau einhält. Gemäss Google Inc. wird in keinem Fall die IP-Adresse mit anderen den Nutzer betreffenden Daten in Verbindung gebracht werden.</span></span></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Weitere Informationen über den genutzten Webanalyse-Dienst finden Sie auf der Website von Google Analytics. Eine Anleitung, wie sie die Verarbeitung ihrer Daten durch den Webanalyse-Dienst verhindern können, finden Sie unter&nbsp;</span><span
                                                style={{fontSize:'medium'}}
                                                data-mce-mark="1">http://tools.google.com/dlpage/gaoptout?hl=de</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">.</span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">8. Hinweis zu Datenübermittlungen in die USA</span></strong></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Aus Gründen der Vollständigkeit weisen wir für Nutzer mit Wohnsitz oder Sitz in der Schweiz darauf hin, dass in den USA Überwachungsmassnahmen von US-Behörden bestehen, die generell die Speicherung aller personenbezogenen Daten sämtlicher Personen, deren Daten aus der Schweiz in die USA übermittelt wurden, ermöglicht. Dies geschieht ohne Differenzierung, Einschränkung oder Ausnahme anhand des verfolgten Ziels und ohne ein objektives Kriterium, das es ermöglicht, den Zugang der US-Behörden zu den Daten und deren spätere Nutzung auf ganz bestimmte, strikt begrenzte Zwecke zu beschränken, die den sowohl mit dem Zugang zu diesen Daten als auch mit deren Nutzung verbundenen Eingriff zu rechtfertigen vermögen. Ausserdem weisen wir darauf hin, dass in den USA für die betroffenen Personen aus der Schweiz keine Rechtsbehelfe vorliegen, die es ihnen erlauben, Zugang zu den sie betreffenden Daten zu erhalten und deren Berichtigung oder Löschung zu erwirken, bzw. kein wirksamer gerichtlicher Rechtsschutz gegen generelle Zugriffsrechte von US-Behörden vorliegt. Wir weisen den Betroffenen explizit auf diese Rechts- und Sachlage hin, um eine entsprechend informierte Entscheidung zur Einwilligung in die Verwendung seiner Daten zu treffen.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">Nutzer mit Wohnsitz in einem Mitgliedstaat der EU weisen wir darauf hin, dass die USA aus Sicht der Europäischen Union&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">–&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">unter anderem aufgrund der in diesem Abschnitt genannten Themen&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">–&nbsp;</span><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">nicht über ein ausreichendes Datenschutzniveau verfügt. Soweit wir in dieser Datenschutzerklärung erläutert haben, dass Empfänger von Daten (wie z.B. Google) ihren Sitz in den USA haben, werden wir entweder durch vertragliche Regelungen zu diesen Unternehmen oder durch die Sicherstellung der Zertifizierung dieser Unternehmen unter dem EU- bzw. Swiss- US-Privacy Schild sicherstellen, dass Ihre Daten bei unseren Partnern mit einem angemessenen Niveau geschützt sind.</span></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                    data-mce-mark="1">&nbsp;</span><span data-mce-mark="1">9. Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der Verarbeitung; Recht auf Datenübertragbarkeit</span></strong></span>
                                </p>
                                <div className="page" title="Page 6">
                                    <div className="layoutArea">
                                        <div className="column">
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sie haben das Recht, über die personenbezogenen Daten, die von uns über Sie gespeichert werden, auf Antrag Auskunft zu erhalten. Zusätzlich haben Sie das Recht auf Berichtigung unrichtiger Daten und das Recht auf Löschung Ihrer personenbezogenen Daten, soweit dem keine gesetzliche Aufbewahrungspflicht oder ein Erlaubnistatbestand, der uns die Verarbeitung der Daten gestattet, entgegensteht.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sie haben zudem das Recht, diejenigen Daten, die Sie uns übergeben haben, wieder von uns herauszuverlangen (Recht auf Datenportabilität). Auf Anfrage geben wir die Daten auch an einen Dritten Ihrer Wahl weiter. Sie haben das Recht, die Daten in einem gängigen Dateiformat zu erhalten.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sie können uns für die vorgenannten Zwecke über die E-Mail-Adresse info@ullrich.ch erreichen. Für die Bearbeitung Ihrer Gesuche können wir, nach eigenem Ermessen, einen Identitätsnachweis verlangen.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">10. Datensicherheit</span></strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmassnahmen, um Ihre bei uns gespeicherten persönlichen Daten gegen Manipulation, teilweisen oder vollständigen Verlust und gegen unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmassnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sie sollten Ihre Zugangsdaten stets vertraulich behandeln und das Browserfenster schliessen, wenn Sie die Kommunikation mit uns beendet haben, insbesondere wenn Sie den Computer gemeinsam mit anderen nutzen.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Auch den unternehmensinternen Datenschutz nehmen wir sehr ernst. Unsere Mitarbeiter und die von uns beauftragten Dienstleistungsunternehmen sind von uns zur Verschwiegenheit und zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet worden.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">11. Aufbewahrung von Daten</span></strong></span></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Wir speichern personenbezogene Daten nur so lange, wie es erforderlich ist, um die oben genannten Tracking- und Analysedienste sowie die weiteren Bearbeitungen im Rahmen unseres berechtigten Interesses zu verwenden. Vertragsdaten werden von uns länger aufbewahrt, da dies durch gesetzliche Aufbewahrungspflichten vorgeschrieben ist. Aufbewahrungspflichten, die uns zur Aufbe- wahrung von Daten verpflichten, ergeben sich aus Vorschriften der Rechnungslegung und aus steuerrechtlichen Vorschriften. Gemäss diesen Vorschriften sind geschäftliche Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren aufzubewahren. Soweit wir diese Daten nicht mehr zur Durchführung der Dienstleistungen für Sie benötigen, werden die Daten gesperrt. Dies bedeutet, dass die Daten dann nur noch für Zwecke der Rechnungslegung und für Steuerzwecke verwendet werden dürfen.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><strong><span
                                                data-mce-mark="1">12. Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde</span></strong></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <p><span style={{fontSize:'medium'}} data-mce-mark="1">Sie haben das Recht, sich jederzeit bei einer Datenschutzaufsichtsbehörde zu beschweren.</span>
                                </p>
                                <p>&nbsp;<strong style={{fontSize:'medium'}}><span data-mce-mark="1">13. Newsletter/E-Mails</span></strong>
                                </p>
                                <div className="page" title="Page 7">
                                    <div className="section">
                                        <div className="layoutArea">
                                            <div className="column">
                                                <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                    data-mce-mark="1">Art und Umfang der Datenverarbeitung</span></span>
                                                </p>
                                                <p><span style={{fontSize:'medium'}} data-mce-mark="1">Auf unseren Websites besteht die Möglichkeit, einen kostenfreien Newsletter zu abonnieren. Um Ihnen regelmäßig den Newsletter zusenden zu können, benötigen wir von Ihnen die folgenden Daten:</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="layoutArea">
                                            <div className="column">
                                                <ul style={{listStylePosition: 'inside'}}>
                                                    <li><span style={{fontSize:'medium'}} data-mce-mark="1">E-Mail Adresse</span>
                                                    </li>
                                                    <li><span style={{fontSize:'medium'}}
                                                              data-mce-mark="1">Vor- &amp; Nachname (optional)</span>
                                                    </li>
                                                    <li><span style={{fontSize:'medium'}} data-mce-mark="1">Anrede (optional)</span>
                                                    </li>
                                                    <li><span style={{fontSize:'medium'}} data-mce-mark="1">Titel (optional)</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="layoutArea">
                                        <div className="column">
                                            <p>&nbsp;</p>
                                            <p><span style={{fontSize:'medium'}}>Um sich bei unserem E-Mail-Newsletterdienst anmelden zu können, benötigen wir neben Ihrer Einwilligung mindestens Ihre E-Mail-Adresse, an die der Newsletter versendet werden soll. Etwaige weitere Angaben sind freiwillig und werden verwendet, um Sie persönlich ansprechen und den Inhalt des Newsletters persönlich ausgestalten zu können sowie Rückfragen zur E-Mail-Adresse klären zu können. Es unterliegt Ihrer freien Entscheidung, ob Sie uns diese Daten mitteilen.&nbsp;</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}>Sollten Sie explizit wünschen, dass Sie keine weiteren E-Mails von uns erhalten, halten wir Ihre E-Mail in einer Liste gespeichert, um sicher zu gehen, dass keine weiteren E-Mails an Sie von uns gesendet werden. Ein Newsletterversand kann unter anderem auch dann erfolgen, wenn uns ein Partner E-Mail-Adressen rechtmäßig zur Versendung unseres Newsletters zur Verfügung stellt. Mit dieser Liste können wir sichergehen, dass sie keinen Newsletter mehr von uns bekommen, selbst wenn sie rechtmäßig von dritter Seite für unseren Newsletter vorgesehen sind.</span>&nbsp;
                                            </p>
                                            <p><span style={{fontSize:'medium'}}>Auch enthalten unsere Newsletter und vergleichbaren E-Mails sogenannte Tracking-Pixel. Mit Ihrer Einwilligung setzen wir innerhalb des Newsletters Tracking-Pixel ein. So kann das Verhalten von Ihnen bezugnehmend auf unseren Newsletter nachverfolgt werden, nachdem diese durch Klick auf Foto oder Link auf unsere Websites weitergeleitet wurden. Dieses Verfahren dient dazu, die Wirksamkeit unseres Newsletters für statistische und Marktforschungszwecke auszuwerten und kann dazu beitragen, zukünftige Werbemaßnahmen zu optimieren.</span>
                                            </p>
                                            <p>&nbsp;</p>
                                            <p><span style={{fontSize:'medium'}}><strong>Rechtsgrundlage</strong></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}>Die Verarbeitung Ihrer E-Mail Adresse sowie Ihres Namens für den Newsletterversand beruht nach Art 6 Abs 1 lit a DSGVO auf der von Ihnen im Folgenden freiwillig abgegebenen Einwilligungserklärung</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}><span style={{fontSize:'medium'}}>Mit dem Anklicken des Feldes „Absenden“ bin ich damit einverstanden, dass die Dr. Brauwolf GmbH meine personenbezogenen Daten (Vor- und Nachname, E-Mail- Adresse) für den bzw. die oben angekreuzten Newsletter zum Zweck der Zusendung dieser Newsletter speichern und verarbeiten darf. Meine oben angeführte(n) Einwilligung(en) kann ich ohne für mich nachteilige Folgen jederzeit formlos mit Wirkung für die Zukunft widerrufen. Meine Widerrufserklärung kann ich postalisch an die Dr. Brauwolf GmbH, Aliothstrasse 40, 4241 Münchenstein (CH), oder per E-Mail an widerruf@ullrich.ch richten. Weitere Informationen über die Verarbeitung personenbezogener Daten finde ich in der Datenschutzerklärung.</span></span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}><span style={{fontSize:'medium'}}>Mit dem Newsletter wird ein sogenanntes „Tracking-Pixel“ versendet, das Dr. Brauwolf GmbH ermöglicht festzustellen, ob der Newsletter vom Empfänger geöffnet wurde. Weitere personenbezogene Daten werden von diesem Tracking-Pixel nicht erhoben. Die Verwendung dieses Tracking-Pixel dient Dr. Brauwolf GmbH dazu, An- und Rückfragen von Empfängern zum Erhalt des Newsletter in technischer Hinsicht beantworten zu können. Darin besteht auch das berechtigte Interesse&nbsp;</span></span><span
                                                style={{fontSize:'medium'}}>gemäß Artikel 6 Abs. 1 lit. f DSGVO.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}>&nbsp;</span></p>
                                            <p><strong><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>Speicherdauer</span></span></strong></p>
                                            <p><span style={{fontSize:'medium'}}>Ihre E-Mail Adresse sowie der Vor- und Nachname werden solange gespeichert, wie Sie den Newsletter abonniert haben. Nach einer Abmeldung vom Newsletter werden Ihre E-Mail Adresse sowie Ihr Vor- und Nachname gelöscht. Eine weitergehende Speicherung kann im Einzelfall erfolgen, wenn dies gesetzlich vorgeschrieben ist.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}>Daten des Tracking Pixel werden solange gespeichert, wie ein Newsletter abonniert ist und die Einwilligung in den Newsletter nicht widerrufen wurde. Eine weitergehende Speicherung kann im Einzelfall erfolgen, wenn dies gesetzlich vorgeschrieben ist.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>&nbsp;</span></span></p>
                                            <p><strong><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>Art und Umfang der Datenverarbeitung</span></span></strong>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}>Auf unseren Websites bieten wir Ihnen an, über eine bereitgestellte E-Mail Adresse mit uns in Kontakt zu treten. Wenn Sie von der Kontaktadresse Gebrauch machen, werden folgende personenbezogene Daten von Ihnen verarbeitet:</span>
                                            </p>
                                            <ul style={{listStylePosition: 'inside'}}>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">E-Mail Adresse</span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Vor- und Nachname</span></li>
                                                <li><span style={{fontSize:'medium'}} data-mce-mark="1">Individuelle Nachricht</span></li>
                                            </ul>
                                            <span style={{fontSize:'medium'}}><br/></span><span
                                            style={{fontSize:'medium'}}>Bei der Nutzung der Kontaktadresse erfolgt keine Weitergabe Ihrer personenbezogenen Daten an Dritte.</span><br/>
                                            <p><span style={{fontSize:'medium'}}><span style={{fontSize:'medium'}}>Es unterliegt Ihrer freien Entscheidung, ob Sie uns diese Daten mitteilen. Ohne&nbsp;</span>diese Angaben können wir allerdings Ihren Kontaktwunsch nicht erfüllen.&nbsp;Die beschriebene Datenverarbeitung zum Zweck der Kontaktaufnahme erfolgt nach Art 6 Abs 1 lit f DSGVO</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>&nbsp;</span></span></p>
                                            <p><strong><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>Speicherdauer</span></span></strong></p>
                                            <p><span style={{fontSize:'medium'}}>Sobald die von Ihnen gestellte Anfrage erledigt und der betreffende Sachverhalt abschließend geklärt ist, werden Ihre verarbeiteten personenbezogenen Daten gelöscht. Eine weitergehende Speicherung kann im Einzelfall erfolgen, wenn dies gesetzlich vorgeschrieben ist.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>&nbsp;</span></span></p>
                                            <p><strong><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>Rechtsgrundlage</span></span></strong></p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1">Bei der Verarbeitung Ihrer personenbezogenen Daten (vgl. § 4 3. a.), die zur Erfüllung eines mit uns geschlossenen Testabonnementvertrages erforderlich sind, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Massnahmen erforderlich sind.</span>
                                            </p>
                                            <p><span style={{fontSize:'medium'}} data-mce-mark="1"><span
                                                style={{fontSize:'medium'}} data-mce-mark="1">&nbsp;</span></span></p>
                                            <p><strong><span style={{fontSize:'medium'}}><span
                                                style={{fontSize:'medium'}}>Speicherdauer</span></span></strong></p>
                                            <p><span style={{fontSize:'medium'}}>Mit Zusendung der werden Ihre Daten für die weitere Verwendung gesperrt und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in die weitere Nutzung Ihrer Daten eingewilligt haben. Eine weitergehende Speicherung kann im Einzelfall dann erfolgen, wenn dies gesetzlich vorgeschrieben ist.</span>
                                            </p>
                                            <p>&nbsp;</p>
                                            <p><span style={{fontSize:'medium'}}>Stand: Februar 2019</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(Detail)
