import React, {Component} from 'react';
import slugify from 'slugify';

class LineItemSummary extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {

      let url = slugify(this.props.line_item.title, {
          replacement: '-',    // replace spaces with replacement
          remove: null,        // regex to remove characters
          lower: true          // result in lower case
      });

      var titleStyle= {
          fontFamily: 'titling',
          fontSize: '24px',
          color: '#000'
      };

      let formattedPrice = this.props.line_item.variant.price.amount.toString().replace(".0", ".-");

      return (
          <div className="item">
              <a href={url} className="item-thumb">
                  {this.props.line_item.variant.image ? <img src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`}/> : null}
              </a>
              <div className="item-details">
                  <h3 className="item-title" style={titleStyle}><a href={url}>{this.props.line_item.title}</a></h3>
                  <small>{this.props.line_item.variant.selectedOptions[0].value}</small><br/>
                  <small>CHF {formattedPrice}</small>
                  <div className="count-input">
                      <a className="incr-btn" data-action="decrease" href="#" onClick={evt => {
                          evt.preventDefault();
                          this.decrementQuantity(this.props.line_item.id);
                      }}>–</a>
                      <input className="quantity" type="text" value={this.props.line_item.quantity}/>
                      <a className="incr-btn" data-action="increase" href="#" onClick={evt => {
                          evt.preventDefault();
                          this.incrementQuantity(this.props.line_item.id);
                      }}>+</a>
                  </div>
              </div>
              <a href="#" className="item-remove" data-toggle="tooltip" data-placement="top" title="" data-original-title="Remove" onClick={evt => {
                  evt.preventDefault();
                  this.props.removeLineItemInCart(this.props.line_item.id);
              }}>
                <i className="material-icons remove_shopping_cart"></i>
              </a>
          </div>
    );
  }
}

export default LineItemSummary;
