import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";

class Footer extends Component {

constructor(props) {
    super(props);

  }

  render() {

      var style1 =
          {
              fontFamily: 'titling',
              fontSize: '19px'
          }

      return (
      <footer className="footer space-top-2x">
      <div className="column">
          <h3 className="widget-title">
              <span style={style1}>
                  WHERE BEER MEETS SCIENCE
              </span>
              <small>
                  <a style={{color:'#999'}} href="impressum"><FormattedMessage id={ 'footer.legal.impressum' } defaultMessage={ 'Impressum' }/></a> | <a style={{color:'#999'}} href="datenschutz"> <FormattedMessage id={ 'footer.legal.data' } defaultMessage={ 'Datenschutz' }/></a>
              </small>
          </h3>
          <div className="social-bar text-center space-bottom">
              <a target="_blank" rel="noopener" href="https://drbrauwolf.ch" className="sb-instagram" data-toggle="tooltip" data-placement="top" title="" data-original-title="drbrauwolf | HOME">
                  <img alt="brauwolf" width="20" src="img/wolfkopf.png"/>
              </a>
              <a aria-label="facebook brauwolf" target="_blank" rel="noopener" href="https://www.facebook.com/drbrauwolf" className="sb-facebook" data-toggle="tooltip" data-placement="top" title="Facebook">
                  <i className="socicon-facebook"></i>
              </a>
              <a aria-label="instagram brauwolf" target="_blank" rel="noopener" href="https://www.instagram.com/drbrauwolf" className="sb-instagram" data-toggle="tooltip" data-placement="top" title="" data-original-title="Instagram">
                  <i className="socicon-instagram"></i>
              </a>
          </div>
          {/*<p className="copyright">&copy; 2018. Made with*/}
              {/*<i className="text-danger material-icons favorite"></i> /*/}
              {/*<i className="text-danger material-icons fas fa-beer"></i> by vatia.*/}
          {/*</p>*/}
      </div>
      <div className="column">
          <h3 className="widget-title">
              <span style={style1}>
                  <FormattedMessage id={ 'footer.payment.title' } defaultMessage={ 'Bezahlmethoden' }/>
              </span>
              <small>
                  <FormattedMessage id={ 'footer.payment.description' } defaultMessage={ 'Wir unterstützen folgende Bezahlmethoden' }/>
              </small>
          </h3>
      <div className="cards" style={{maxWidth:'100%'}}><img style={{width:374, maxWidth:300, margin:"auto"}} src="img/cards3.png" alt="Cards"/></div>
  </div>
  <div className="column">
      <h3 className="widget-title">
        <span style={style1}>
            <FormattedMessage id={ 'footer.delivery.title' } defaultMessage={ 'Versandkosten' }/>
        </span>
        <small>
            <FormattedMessage id={ 'footer.delivery.description' } defaultMessage={ 'Der Versand unserer Biere ist kostenlos' }/>
        </small>
      </h3>
      <a href="cart" className="add-to-cart">
          <em>
              <FormattedMessage id={ 'footer.cart.link' } defaultMessage={ 'Zum Warenkorb' }/></em>
          <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
              <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"></path>
          </svg>
      </a>
      <div className="scroll-to-top-btn"><i className="material-icons trending_flat"></i></div>
      </div>
  </footer>
    );
  }
}

export default Footer;
