import React, { Component } from 'react';
import Header from './Header';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import LineItemSummary from "./LineItemSummary";
import Media from "react-media"
import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
import Footer from "./Footer";
import {FormattedMessage} from "react-intl";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Summary extends Component {

    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
        this.updateDiscounts = this.updateDiscounts.bind(this);

        this.openCheckout = this.openCheckout.bind(this);
    }

    openCheckout() {
        const cookies = new Cookies();
        if(cookies.get('ls_lang'))
        {
            window.open(this.state.checkout.webUrl+'&locale='+cookies.get('ls_lang'));
        }
        else
        {
            window.open(this.state.checkout.webUrl);
        }
    }

    componentDidMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res.order)
                {
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            // var filteredProducts = [];
            // unfilteredProducts.forEach(product => {
            //     if(product.tags != null && product.tags.length > 0) {
            //         var tags = product.tags.map(tag => tag.value);
            //         if (!tags.includes("deactivated")) {
            //             filteredProducts.push(product);
            //         }
            //     }
            // });
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    async updateDiscounts(checkout) {
        this.setState({updating: true})
        await setTimeout(() => {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {
                this.setState({checkout:res, updating: false});
            });
        }, 2000);
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id;
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}];

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
            this.updateDiscounts(res);
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id;

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
            this.updateDiscounts(res);
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            var animating = false;

            var addToCartBtn = $(this).find('.add-to-cart');

            if(!animating) {
                //animate if not already animating
                animating =  true;
                // resetCustomization(addToCartBtn);

                addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                    //draw the check icon
                    'stroke-dashoffset':0
                }, 300, function(){
                    setTimeout(function(){
                        // updateCart();
                        addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                            //wait for the end of the transition to reset the check icon
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        });

                        if( $('.no-csstransitions').length > 0 ) {
                            // check if browser doesn't support css transitions
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        }
                    }, 600);
                });
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
        const checkoutId = this.state.checkout.id;

        return client.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    render() {

        let checkoutButton = null;
        if(this.state.checkout.subtotalPrice && this.state.checkout.subtotalPrice.amount > 0)
        {
            checkoutButton = <a href="#" className="btn btn-primary btn-block waves-effect waves-light space-top-none" onClick={evt => {
                evt.preventDefault();
                this.openCheckout();
            }}>
                <FormattedMessage id={ 'summary.checkout.link' } defaultMessage={ "Zum Checkout" }/>
            </a>
        }

        let checkoutButtonTop = null;
        if(this.state.checkout.subtotalPrice > 0)
        {
            checkoutButtonTop = <Media
                query="(max-width: 768px)"
                render={() => <a href="#" className="btn btn-primary btn-block waves-effect waves-light space-top-none" onClick={evt => {
                    evt.preventDefault();
                    this.openCheckout();
                }}>
                    <FormattedMessage id={ 'summary.checkout.link' } defaultMessage={ "Zum Checkout" }/>
                </a>}
            />
        }

        let line_items = this.state.checkout.lineItems.map((line_item) => {
            return (
                <LineItemSummary
                    updateQuantityInCart={this.updateQuantityInCart}
                    removeLineItemInCart={this.removeLineItemInCart}
                    key={line_item.id.toString()}
                    line_item={line_item}
                />
            );
        });

        if(this.state.checkout.lineItems.length === 0)
        {
            line_items = <p><FormattedMessage id={ 'cart.empty' } defaultMessage={ 'Es befinden sich keine Produkte im Warenkorb.' }/></p>;
        }

        let formattedDiscount = "0.00";
        if(this.state.checkout.discountApplications)
        {
            let discounts = this.state.checkout.discountApplications.length;
            if(discounts > 0)
            {
                let discountApplication = this.state.checkout.discountApplications[0];
                let amount = discountApplication.value.amount+"0";
                formattedDiscount = amount;
            }
        }

        console.log("subtotalPrice", this.state.checkout.subtotalPrice)

        return (
            <div className="App">
                <div className="page-wrapper">
                    <Header
                        products={this.state.products}
                        checkout={this.state.checkout}
                        isCartOpen={this.state.isCartOpen}
                        handleCartClose={this.handleCartClose}
                        updateQuantityInCart={this.updateQuantityInCart}
                        removeLineItemInCart={this.removeLineItemInCart}
                    />
                    <section className="container padding-bottom padding-top">
                        <p><a href='/'><FormattedMessage id={ 'summary.back' } defaultMessage={ "Zurück zum Shop" }/></a></p>
                        {checkoutButtonTop}
                        <h1 className="space-top-half"><FormattedMessage id={ 'summary.title' } defaultMessage={ "Warenkorb" }/></h1>
                        <div className="row padding-top">
                            <div className="col-sm-8 padding-bottom-2x">
                                <div className="shopping-cart">
                                    {line_items}
                                </div>
                            </div>

                            <div className="col-md-3 col-md-offset-1 col-sm-4 padding-bottom-2x">
                                <aside>
                                    <h3 className="toolbar-title"><FormattedMessage id={ 'summary.subtotal' } defaultMessage={ "Warenkorb Total:" }/></h3>
                                    {this.state.checkout.subtotalPrice ?
                                    <h4 className="amount">CHF {this.state.updating ? '.. calculating' : this.state.checkout.subtotalPrice.amount.replace(".0", ".-")}</h4> : null}
                                    <p className="text-sm text-gray"><FormattedMessage id={ 'summary.delivery.costs' } defaultMessage={ "inkl. Porto / Versandkosten / Rabatte" }/> CHF -{this.state.updating ? '.. calculating' : formattedDiscount}</p>
                                    {checkoutButton}
                                    <FormattedMessage id={ 'cart.agb.prefix' } defaultMessage={ "Mit der Bestellung bestätige ich die " }/>
                                    <Link to="/pdf/AGB.pdf" target='_blank' href="/pdf/AGB.pdf" onClick={(event) => {event.preventDefault(); window.open(this.makeHref("/pdf/AGB.pdf"));}}>
                                        <FormattedMessage id={ 'cart.agb' } defaultMessage={ "AGB" }/>
                                    </Link>
                                    <FormattedMessage id={ 'cart.agb.age' } defaultMessage={ " und damit, dass ich mind. 18 Jahre alt bin" }/>.
                                </aside>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(Summary)