import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import Cookies from 'universal-cookie';

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class User extends Component {

    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
            heroContent: '',
            loadingData: true
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            // var filteredProducts = [];
            // unfilteredProducts.forEach(product => {
            //     if(product.tags != null && product.tags.length > 0) {
            //         var tags = product.tags.map(tag => tag.value);
            //         if (!tags.includes("deactivated")) {
            //             filteredProducts.push(product);
            //         }
            //     }
            // });
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            if(this.id === variantId)
            {
                var animating = false;

                var addToCartBtn = $(this).find('.add-to-cart');

                if(!animating) {
                    //animate if not already animating
                    animating =  true;
                    // resetCustomization(addToCartBtn);
                    addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                        //draw the check icon
                        'stroke-dashoffset':0
                    }, 300, function(){
                        setTimeout(function(){
                            // updateCart();
                            addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                                //wait for the end of the transition to reset the check icon
                                addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                                animating =  false;
                            });

                            if( $('.no-csstransitions').length > 0 ) {
                                // check if browser doesn't support css transitions
                                addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                                animating =  false;
                            }
                        }, 600);
                    });
                }
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
        const checkoutId = this.state.checkout.id;

        // console.log("adding variant in home");
        // let count = this.state.checkout.lineItems.map((line_item) => {return line_item.quantity;}).reduce(function(a,b) {return a+b;},0) + quantity;
        // let discountCode = "";
        // if(count == 1)
        // {
        //     //Removes the applied discount from an existing checkout.
        //     client.checkout.removeDiscount(this.state.checkout.id).then(checkout => {
        //         console.log("removed discount");
        //     });
        // }
        // else if(count == 2)
        // {
        //     discountCode = 'DM7A2CR4DFP4';
        // }
        // else if(count == 3)
        // {
        //     discountCode = 'R86XDV0Z6S68';
        // }
        // else if(count == 4)
        // {
        //     discountCode = 'N7GYCT233BEY';
        // }
        // else if(count == 5)
        // {
        //     discountCode = 'D7JYFRYS6GSS';
        // }
        //
        // if(discountCode != "" && this.state.checkout.discountApplications)
        // {
        //     if (this.state.checkout.discountApplications.length == 0 && count > 1)
        //     {
        //         client.checkout.addDiscount(this.state.checkout.id, discountCode).then(checkout =>
        //         {
        //             console.log("added new discount", discountCode);
        //         });
        //     }
        //     else
        //     {
        //         if(this.state.checkout.discountApplications[0] != null && this.state.checkout.discountApplications[0].code != discountCode)
        //         {
        //             client.checkout.addDiscount(this.state.checkout.id, discountCode).then(checkout =>
        //             {
        //                 console.log("added new discount", discountCode);
        //             });
        //         }
        //     }
        // }

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    render()
    {
        document.onkeydown = checkKey;

        function checkKey(e) {

            e = e || window.event;

            if (e.keyCode == '38') {
                // up arrow
            }
            else if (e.keyCode == '40') {
                // down arrow
            }
            else if (e.keyCode == '37') {
                // left arrow
                document.querySelector('.owl-prev').click();
            }
            else if (e.keyCode == '39') {
                // right arrow
                document.querySelector('.owl-next').click();
            }

            else if (e.keyCode == '13') {
                document.querySelector('.active').querySelector('.detail').click();
            }

            else if (e.keyCode == '27') {
                document.querySelector('.scroll-to-top-btn').click();
            }
        }
        
        const searchParams = new URLSearchParams(document.location.search)
        let userCode = searchParams.get('code');
        let url = "https://sub.vatia.io/user?code=" + userCode;
        console.log(url);

        let iframe = <iframe frameborder="0" border="0" cellspacing="0"
                             style={{borderStyle: "none", width: "100%", height: "1000px"}}
                             src={url}></iframe>;

        return (
            <div className="App">
                <Header
                    products={this.state.products}
                    checkout={this.state.checkout}
                    isCartOpen={this.state.isCartOpen}
                    handleCartClose={this.handleCartClose}
                    updateQuantityInCart={this.updateQuantityInCart}
                    removeLineItemInCart={this.removeLineItemInCart}
                />
                {iframe}
                <Footer/>
            </div>
        )
    }
}

export default withRouter(User)
