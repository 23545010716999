import React, {Component} from 'react';
import Client from 'shopify-buy';
import slugify from 'slugify';
import Media from "react-media";
import {IntlProvider, FormattedMessage} from 'react-intl';
import $ from "jquery";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import VariantSelector from "./VariantSelector"; // ES6
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import LazyLoad from 'react-lazyload';
import { useMediaPredicate } from "react-media-hook";

const ProductImage = ({src, srcset, alt}) =>
{
    const biggerThan768 = useMediaPredicate("(min-width: 768px)");
    const biggerThan992 = useMediaPredicate("(min-width: 992px)");
    const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");

    const size1 = 156;
    const size2 = 436;
    const size3 = 372;
    const size4 = 333;

    var minHeight = null;
    if(biggerThan1200) minHeight = size4;
    if(!biggerThan1200) minHeight = size3;
    if(!biggerThan992) minHeight = size2;
    if(!biggerThan768) minHeight = size1;

    return <LazyLoad height={minHeight}>
        <img
            style={{minHeight:minHeight}}
            src={src}
            srcSet={srcset}
            sizes="(max-width: 768px) 345px, (min-width: 768px) 345px, (min-width: 992px) 213px, (min-width: 1200px) 263px"
            alt={alt}
            effect="blur"
        />
    </LazyLoad>
};

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            variants: this.props.product.variants.filter(variant => variant.sku != "LUCKABOX"),
            mainOption: this.props.product.options[0],
            mainOptionValues: this.props.product.options[0].values.filter(value => value.value != "Heiml. ZH 24er")
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleOptionChangeBoxes = this.handleOptionChangeBoxes.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.findImage = this.findImage.bind(this);
    }

    componentWillMount() {
        this.props.product.options.forEach((selector) => {
            this.setState({
                selectedOptions: { [selector.name]: selector.values[0].value }
            });
        });
    }

    findImage(images, variantId) {
        const primary = images[0];

        const image = images.filter(function (image) {
            return image.variant_ids.includes(variantId);
        })[0];

        return (image || primary).src;
    }

    async handleOptionChange(event)
    {
        const target = event.target;
        let selectedOptions = this.state.selectedOptions;
        selectedOptions[target.name] = target.value;

        const selectedVariant = await this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions);

        this.setState({
            selectedVariant: selectedVariant,
            selectedVariantImage: selectedVariant.attrs.image
        });
    }

    async handleOptionChangeBoxes(option, name)
    {
        let selectedOptions = this.state.selectedOptions;
        selectedOptions[name] = option.value;

        const selectedVariant = await this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions);

        this.setState({
            selectedVariant: selectedVariant,
            selectedVariantImage: selectedVariant.attrs.image
        });
    }

    handleQuantityChange(event) {
        this.setState({
            selectedVariantQuantity: event.target.value
        });
    }

    decrementQuantity(lineItem) {
        const updatedQuantity = lineItem.quantity - 1;
        this.props.updateQuantityInCart(lineItem.id, updatedQuantity);
    }

    incrementQuantity(lineItem) {
        const updatedQuantity = lineItem.quantity + 1;
        this.props.updateQuantityInCart(lineItem.id, updatedQuantity);
    }

    render() {
        let variantImage = this.state.selectedVariantImage || this.props.product.images[0];
        let variantImage2 = this.state.selectedVariantImage || this.props.product.images[1];
        let variantImage3 = this.state.selectedVariantImage || this.props.product.images[2];
        let variantImage4 = this.state.selectedVariantImage || this.props.product.images[3];
        let variant = this.state.selectedVariant || this.state.variants[0];
        let variantQuantity = this.state.selectedVariantQuantity || 1;

        var quantityStyle = {
            // position: 'relative',
            margin: '0px',
            padding: '0px',
            width: '114px',
            // marginLeft:'16px'
            display: "inline-block"
        };

        var titleStyle= {
            fontFamily: 'titling',
            fontSize: '20px',
            color: '#000',
            maxLines: 3
        };

        var lineStyle= {
            borderBottom: '2px solid #ededed'
        }

        var cartSmall = {
            padding: '0px',
            marginTop: '-3px',
            marginLeft: '-3px'
        }

        let separationLine = <Media
            query="(max-width: 768px)"
            render={() => <div style={lineStyle}/>}
        />

        let url = slugify(this.props.product.title, {
            replacement: '-',    // replace spaces with replacement
            remove: null,        // regex to remove characters
            lower: true          // result in lower case
        });

        let lineItem = this.props.checkout.lineItems
            .filter(line_item => line_item.variant.id === variant.id)
            .map((line_item) => {

                let customId = url.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,"");

                return (
                    <div className="count-input" style={quantityStyle} key={line_item.id}>
                        <a className="incr-btn" data-action="decrease" href="#" onClick={evt => {
                            evt.preventDefault();
                            $('#' + customId + '-quantity').val('...');
                            this.decrementQuantity(line_item);
                        }}>–</a>
                        <input className="quantity" type="text" value={line_item.quantity} id={customId + '-quantity'}/>
                        <a className="incr-btn" data-action="increase" href="#" onClick={evt => {
                            evt.preventDefault();
                            $('#' + customId + '-quantity').val('...');
                            this.incrementQuantity(line_item);
                        }}>+</a>
                    </div>
                );
            });

        if(lineItem.length === 0)
        {
            lineItem =  <span><a className="add-to-cart" href="#" onClick={evt => {
                evt.preventDefault();
                this.props.addVariantToCart(variant.id, variantQuantity);
            }}>
                <em><span className="material-icons shopping_basket" style={cartSmall}></span>
                    &nbsp;&nbsp;<FormattedMessage
                        id={ 'list.product.add' }
                        defaultMessage={ 'zufügen' }
                        values={{ name }}
                    />
                </em>
               <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                   <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"></path>
               </svg></a><br/></span>
        }

        if("Abo" === this.props.product.title)
        {
            lineItem = (<span><a className="add-to-cart" href="/abo">
                <em><span className="material-icons shopping_basket" style={cartSmall}></span>
                    &nbsp;&nbsp;<FormattedMessage
                        id={ 'list.product.abo' }
                        defaultMessage={ 'Anmelden' }
                        values={{ name }}
                    />
                </em>
               <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                   <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"></path>
               </svg></a><br/></span>);
        }

        let formattedPrice = variant.price.amount.toString().replace(".0", ".-");
        let srcset = null;
        if(variantImage != null && variantImage2 != null && variantImage3 != null && variantImage4 != null)
        {
            srcset = variantImage.src + " 723w," + variantImage2.src + " 345w," + variantImage3.src + " 213w," + variantImage4.src + " 263w";
        }
        else if(variantImage != null)
        {
            srcset = variantImage.src + " 723w," + variantImage.src + " 345w," + variantImage.src + " 213w," + variantImage.src + " 263w";
        }

        let specialInfo = "";
        for(var i=0; i < variant.selectedOptions.length ; i++)
        {
            if (variant.selectedOptions[i].name == "Info")
            {
                specialInfo = <span className="shop-label" style={{
                    backgroundColor: "#ededed",
                    left: "5px"
                }}>{ReactHtmlParser(variant.selectedOptions[i].value)}</span>
            }
        }
        if (url == "summer-ale") {
            specialInfo = <span className="shop-label" style={{
                backgroundColor: "black",
                color: "orange",
                // left: "5px",
                fontSize: 18,
                marginTop: "50%",
                marginLeft: "auto"
            }}>Buy 1 Get 1 Free (24er)</span>
        }

        let variantSelectors = null
        if(this.state.variants.length>1)
        {
            variantSelectors =  <span><VariantSelector
                handleOptionChange={this.handleOptionChange}
                handleOptionChangeBoxes={this.handleOptionChangeBoxes}
                key={this.state.mainOption.id.toString()}
                option={this.state.mainOption}
                values={this.state.mainOptionValues}
                selected={this.state.selectedVariant}
            /></span>
        }
        else
        {
            let singleValue = this.state.mainOptionValues[0].value;
            variantSelectors =  <div><span style={{height: 35,paddingTop:4,display:"inline-block"}}>
             <FormattedMessage id={singleValue} defaultMessage={singleValue}/>
             <br/>
         </span><br/>
            </div>
        }

        var option2 = variant.selectedOptions[1].value;
        var highlightPrice = false;
        if(option2.includes("statt"))
        {
            highlightPrice = true;
            option2 = (<span><FormattedMessage id={'statt'} defaultMessage={'statt'}/> <strike>{option2.replace("statt ","")}</strike></span>)
        }

        return (
            // <ReactCSSTransitionGroup
            // transitionName="example"
            // transitionEnterTimeout={500}
            // transitionLeaveTimeout={300}>
            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6" style={{minHeight:402}}>
                <div className="shop-item" id={variant.id} key={variant.id}>
                    <div className="shop-thumbnail">
                        {specialInfo}
                        <a href={url} aria-label={url} className="item-link"></a>
                        {this.props.product.images.length ?
                            <ProductImage src={variantImage.src} srcset={srcset} alt={`${this.props.product.title} product shot`}/>
                            : null}
                        <div className="shop-item-tools">
                        </div>
                    </div>
                    <span className="visible-sm visible-lg visible-md">
              <div className="shop-item-details">
                <h3 className="shop-item-title" style={{width:"95%"}}>
                    <a href={url} style={titleStyle}><FormattedMessage id={ 'detail.product.title.'+url} defaultMessage={ this.props.product.title }/></a>
                    <br/><small style={{color: highlightPrice ? "red":"#606060"}}>CHF {formattedPrice}</small>
                    <br/><small><i style={{fontSize:'11px'}}><FormattedMessage id={option2} defaultMessage={option2}/></i></small>
                </h3>
                <span className="shop-item-price">
                    {variantSelectors}
                    {lineItem}
                </span>
            </div>
            </span>
                    <span className="visible-xs" style={{minHeight: 196, height: 196}}>
                <a href={url} style={titleStyle}><FormattedMessage id={ 'detail.product.title.'+url} defaultMessage={ this.props.product.title }/></a>
                <br/><small style={{margin:0, color: highlightPrice ? "red":"#606060"}}>CHF {formattedPrice}</small>
                <br/><div style={{fontSize:'11px', padding:0, margin:0, marginBottom:"-15px"}}>
                    <small ><i><FormattedMessage id={option2} defaultMessage={option2}/></i></small>
                </div>
                <br/>{variantSelectors}
                        {lineItem}
            </span>
                </div>
                {/*{separationLine}*/}
            </div>
            // </ReactCSSTransitionGroup>
        );
    }
}

export default Product;