import React, { Component } from 'react';
import Header from './Header';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import ProductDetailNav from "./ProductDetailNav";
import ProductDetailInfo from "./ProductDetailInfo";
// import ProductDetailTabs from "./ProductDetailTabs";
import slugify from 'slugify';
import Cookies from "universal-cookie";
import Footer from "./Footer";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Detail extends Component {

    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);

        this.openCheckout = this.openCheckout.bind(this);
    }

    openCheckout() {
        window.open(this.props.checkout.webUrl);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            console.log("creating new checkout id");
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    console.log("checkout is now invalid");
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            // var filteredProducts = [];
            // unfilteredProducts.forEach(product => {
            //     if(product.tags != null && product.tags.length > 0) {
            //         var tags = product.tags.map(tag => tag.value);
            //         if (!tags.includes("deactivated")) {
            //             filteredProducts.push(product);
            //         }
            //     }
            // });
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            var animating = false;

            var addToCartBtn = $(this).find('.add-to-cart');

            if(!animating) {
                //animate if not already animating
                animating =  true;
                // resetCustomization(addToCartBtn);

                addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                    //draw the check icon
                    'stroke-dashoffset':0
                }, 300, function(){
                    setTimeout(function(){
                        // updateCart();
                        addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                            //wait for the end of the transition to reset the check icon
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        });

                        if( $('.no-csstransitions').length > 0 ) {
                            // check if browser doesn't support css transitions
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        }
                    }, 600);
                });
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
        const checkoutId = this.state.checkout.id

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    render()
    {
        let product = null;
        let previous = null;
        let next = null;

        let pageId = this.props.match.params.id;
        let products = this.state.products;

        for (var i = 0; i < products.length; i++)
        {
            if(slugify(products[i].title, {
                    replacement: '-',    // replace spaces with replacement
                    remove: null,        // regex to remove characters
                    lower: true          // result in lower case
                }) === pageId)
            {
                product = products[i];

                if(i-1 < 0) {
                    previous = products[products.length - 1];
                }
                else {
                    previous = products[i - 1];
                }

                if(i+1 > products.length - 1)
                {
                    next = products[0];
                }
                else
                {
                    next = products[i+1];
                }

                document.title = "drbrauwolf | " + product.title.toUpperCase();
            }
        }

        document.onkeydown = checkKey;

        function checkKey(e) {

            var previousUrl = slugify(previous.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            var nextUrl = slugify(next.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            e = e || window.event;

            if (e.keyCode == '38') {
                // up arrow
            }
            else if (e.keyCode == '40') {
                // down arrow
            }
            else if (e.keyCode == '37') {
                document.location.href = previousUrl;
            }
            else if (e.keyCode == '39') {
                console.log(nextUrl);
                document.location.href = nextUrl;
            }

            else if (e.keyCode == '13') {
                document.querySelector('.buy').click();
            }

            else if (e.keyCode == '27') {
                document.location.href = "/";
            }

        }

        document.addEventListener('touchstart', handleTouchStart, false);
        document.addEventListener('touchmove', handleTouchMove, false);

        var xDown = null;
        var yDown = null;

        function handleTouchStart(evt) {
            xDown = evt.touches[0].clientX;
            yDown = evt.touches[0].clientY;
        };

        function handleTouchMove(evt) {
            if ( ! xDown || ! yDown ) {
                return;
            }

            var previousUrl = slugify(previous.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            var nextUrl = slugify(next.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;

            var xDiff = xDown - xUp;
            var yDiff = yDown - yUp;

            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    /* left swipe */
                    document.location.href = previousUrl;
                } else {
                    document.location.href = nextUrl;
                }
            } else {
                if ( yDiff > 0 ) {
                    /* up swipe */
                } else {
                    /* down swipe */
                }
            }
            /* reset values */
            xDown = null;
            yDown = null;
        };

        var titleStyle = {
            fontFamily: 'titling',
            fontSize: '36px',
            color: '#000'
        };

        return (
            <div className="App">
                <div className="page-wrapper">
                      <Header
                          products={this.state.products}
                          checkout={this.state.checkout}
                          isCartOpen={this.state.isCartOpen}
                          handleCartClose={this.handleCartClose}
                          updateQuantityInCart={this.updateQuantityInCart}
                          removeLineItemInCart={this.removeLineItemInCart}
                      />
                    <section className="fw-section bg-gray padding-bottom-3x">
                        <div className="padding-top-2x text-center">
                            <h1 className="h2 space-bottom-half" style={titleStyle}>Impressum</h1>
                            <div className="container">
                                <p><span>Stefan Wolf</span><br/>Geschäftsführer / Inhaber</p>
                                <p>Dr. Brauwolf GmbH<br/>Räffelstrasse 26<br/>CH-8045 Zürich</p>
                                <p><a href="mailto:info@drbrauwolf.ch">info@drbrauwolf.ch</a></p>
                                <p>RAMPENVERKAUF:<br/>Donnerstag &amp; Freitag | 17.00 – 20.00 Uhr</p>
                            </div>
                        </div>
                    </section>
                    {/*<ProductDetailTabs*/}
                        {/*product={product}*/}
                    {/*/>*/}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(Detail)
