import React, {Component} from 'react';
import Cart from './Cart';
import {FormattedMessage} from "react-intl";
import {withRouter} from 'react-router-dom';

class Header extends Component
{
  constructor(props)
  {
    super(props);

    var active = 3; // shop
    if(props.location != null && props.location.pathname != null)
    {
      if (props.location.pathname.includes("heimlieferung")) active = 2;
      if (props.location.pathname.includes("abo")) active = 1;
    }

    this.state = {
        active: active
    };
  }

  render() {

      var logoStyle =
      {
          maxWidth: '100px'
      };

    return (
        <div>
        <header className="navbar navbar-sticky">
            <a href="/" className="site-logo visible-desktop">
                <img src="/img/logo.png" width="196" alt="Dr. Brauwolf"/>
            </a>
            <a href="/" className="site-logo visible-mobile">
                <img src="/img/logo.png" width="196" style={logoStyle} alt="Dr. Brauwolf"/>
            </a>

            <nav className="main-navigation text-center">
                <div className="middle-nav visible-desktop d-none d-lg-block" style={{width:'100%', margin:'auto', marginLeft:-30, backgroundColor:"black", height:30, textAlign:"center", verticalAlign:"middle", marginTop:29}}>
                    <div style={{display:"inline-block", marginLeft:'auto',paddingLeft:20,paddingRight:20}}>
                        <a style={{textDecoration: this.state.active == 1 ? "underline":"none"}} href="/abo"><FormattedMessage id={ 'nav.middle.subscription' } defaultMessage={ 'Abo' }/></a>
                    </div>
                    <div style={{ display:"inline-block", marginLeft:'auto',paddingLeft:20,paddingRight:20}}>
                        <a style={{textDecoration: this.state.active == 2 ? "underline":"none"}} href="/heimlieferung"><FormattedMessage id={ 'nav.middle.delivery.home' } defaultMessage={ 'Heimlieferung' }/></a>
                    </div>
                    <div style={{ display:"inline-block", marginLeft:'auto',paddingLeft:20,paddingRight:20}}>
                        <a style={{textDecoration: this.state.active == 3 ? "underline":"none"}} href="/"><FormattedMessage id={ 'nav.middle.delivery.mail' } defaultMessage={ 'Shop' }/></a>
                    </div>
                </div>
            </nav>

            <Cart
                products={this.props.products}
                checkout={this.props.checkout}
                isCartOpen={this.props.isCartOpen}
                handleCartClose={this.props.handleCartClose}
                updateQuantityInCart={this.props.updateQuantityInCart}
                removeLineItemInCart={this.props.removeLineItemInCart}
            />
        </header>
        <div id="message middle-nav-message">
            <div className="middle-nav visible-mobile d-none d-lg-block" style={{width:'100%', margin:'auto', backgroundColor:"black", height:40, marginTop:0, textAlign:"center"}}>
                <div style={{display:"inline-block", marginLeft:'auto',paddingLeft:10,paddingRight:10}}>
                    <a style={{textDecoration: this.state.active == 1 ? "underline":"none"}} href="/abo"><FormattedMessage id={ 'nav.middle.subscription' } defaultMessage={ 'Abo' }/></a>
                </div>
                <div style={{ display:"inline-block", marginLeft:'auto',paddingLeft:10,paddingRight:10}}>
                    <a style={{textDecoration: this.state.active == 2 ? "underline":"none"}} href="/heimlieferung"><FormattedMessage id={ 'nav.middle.delivery.home' } defaultMessage={ 'Heimlieferung' }/></a>
                </div>
                <div style={{ display:"inline-block", marginLeft:'auto',paddingLeft:10,paddingRight:10}}>
                    <a style={{textDecoration: this.state.active == 3 ? "underline":"none"}} href="/"><FormattedMessage id={ 'nav.middle.delivery.mail' } defaultMessage={ 'Postversand' }/></a>
                </div>
            </div>
          <div style={{padding: 5,marginBottom:0,width:'100%',backgroundColor:'#6d6d6d',textAlign:'center',color:'white', textSize:'16vw', display: this.state.active == 1 ? 'none' : null}}>
            <div id="inner-message" className="alert alert-error">
              {/*- <a href="/express"><FormattedMessage id={ 'express.link' } defaultMessage={ 'Expresslieferung' }/></a>*/}
              {/*<a target="_blank" href="/pdf/there_is_still_craft_beer.pdf"><FormattedMessage id={ 'message.delivery.special' } defaultMessage={ 'Spezielle Infos zur aktuellen Situation' }/></a> -*/}
                {this.state.active == 2 ? <FormattedMessage id={ 'message.homedelivery' } defaultMessage={ 'Kostenlose Heimlieferung in der Stadt Zürich jeden FR Abend' }/> : null}
                {this.state.active == 3 ? <FormattedMessage id={ 'message.swissdelivery' } defaultMessage={ 'Schweizweite Lieferung' }/> : null}
                {this.state.active == 2 ? <FormattedMessage id={ 'message.homedelivery.tooltip' } defaultMessage={ 'Muss bis Fr. 14:00 bestellt sein' }>
                    {(msg) => (
                        <button style={{margin:0, padding:0, background:"white", color:"black", marginLeft:6, paddingTop:-60, lineHeight:0, height:15, width:15, marginTop:-2, textTransform: "lowercase", fontSize:"8"}}
                                type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="right"
                                title={msg}>
                            i
                        </button>
                    )}
                </FormattedMessage> : null }
                {this.state.active == 2 || this.state.active == 3 ?<span>{' '}|{' '} </span>: null}
                {this.state.active == 3 ?<FormattedMessage id={ 'message.delivery' } defaultMessage={ 'Alle Lieferungen sind kostenlos' }/> : null}
                {this.state.active == 3 ?<span>{' '}|{' '} </span>: null}
                {this.state.active == 3 || this.state.active == 2 ? <FormattedMessage id={ 'message.discount' } defaultMessage={ '5.- CHF Rabatt auf jedes weitere Pack' }/> : null}
                {this.state.active == 3 || this.state.active == 2 ? <FormattedMessage id={ 'message.discount.tooltip' } defaultMessage={ 'Nur Bier & H2OP, alle Varianten' }>
              {(msg) => (
                  <button style={{margin:0, padding:0, background:"white", color:"black", marginLeft:6, paddingTop:-60, lineHeight:0, height:15, width:15, marginTop:-2, textTransform: "lowercase", fontSize:"8"}}
                          type="button" className="btn btn-secondary" data-toggle="tooltip" data-placement="right"
                          title={msg}>
                      i
                  </button>
              )}
                </FormattedMessage> : null}
              {/*<br/><a target="_blank" href="https://www.ticketino.com/de/Event/FIESTA-MEXICANA/115840">Fiesta Mexicana Tickets</a> - <a href="/abo"><FormattedMessage id={ 'abo.link' } defaultMessage={ 'Hol dir jetzt das Dr. Brauwolf Abo!' }/></a>*/}
            </div>
          </div>
        </div>
        </div>
    );
  }
}

export default withRouter(Header);
