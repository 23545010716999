import React from 'react';
import PropTypes from 'prop-types';

import '../assets/stylesheets/base.scss';
import '../public/css/vendor/material-icons.min.css'
import '../public/css/vendor/socicon.min.css'
import '../public/css/vendor/bootstrap.min.css'
import '../public/css/theme.min.css'

// import '../public/js/vendor/page-preloading'
// import '../public/js/vendor/modernizr.custom'

import {addLocaleData, IntlProvider} from 'react-intl';
import Cookies from "universal-cookie";
import initReactFastclick from 'react-fastclick';
initReactFastclick();

const App = ({ children }) => {

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    };

    var currentLanguageByParameter = getUrlParameter('lang');

    let de = {
        "abo.new.timeframe.cancel.anytime": "Jederzeit kündbar",
        "abo.new.navigation.step1.size": "Grösse",
        "abo.new.navigation.step2.timeframe": "Laufzeit",
        "abo.new.navigation.step3.delivery": "Lieferadresse",
        "abo.new.navigation.step4.payment": "Zahlung",
        "abo.new.timeframe.text.monthly": "Monatlich",
        "abo.link": "Hol dir jetzt das Dr. Brauwolf Abo!",
        "abo.title": "Dr. Brauwolf Abo",
        "abo.line1": "Erhalte jeden Monat das aktuellste Monatsbier (Craft Beer of the Month) deiner Lieblingsbrauerei. Die Versandliste wird jeweils am 10. des Monats erstellt.",
        "abo.line2": " ",
        "abo.success":"Du wurdest erfolgreich abonniert! Die erste Lieferung kommt Anfang nächsten Monats. Deine Kreditkarte wird von nun an regelmässig belastet solange das Abo aktiv ist.",
        "abo.success2":"Du wurdest erfolgreich abonniert! Die erste Lieferung kommt Anfang nächsten Monats. Das Abo dauert bis zum Ende des gewählten Zeitraums und wird dann automatisch beendet.",
        "abo.stop":"Schreibe uns ein Mail an info@drbrauwolf.ch oder benutze den Login, wenn du das Abo ändern oder künden möchtest.",
        "abo.fail":"Abonnieren war nicht erfolgreich.",
        "abo.again":"Versuche es nochmals",
        "abo.firstname": "Vorname",
        "abo.lastname": "Nachname",
        "abo.address": "Adresse",
        "abo.plz": "Postleitzahl",
        "abo.city": "Stadt",
        "abo.phone": "Telefon",
        "abo.size": "Abogrösse",
        "abo.button" : "Abonnieren",
        "abo.email" : "Email",
        "cart.subtotal": "Total:",
        "cart.delivery.costs": "Versandkosten:",
        "cart.agb.prefix": "Mit der Bestellung bestätige ich die ",
        "cart.agb": "AGB",
        "cart.empty": "Es befinden sich keine Produkte im Warenkorb.",
        "hero.link": "Zum Angebot",
        "hero.probier.title": "Probierpack",
        "hero.probier.description1": "Eine Auswahl ",
        "hero.probier.description2": "unserer Sorten",
        "list.product.add": "zufügen",
        "footer.payment.title": "Bezahlmethoden",
        "footer.payment.description": "Wir unterstützen folgende Bezahlmethoden",
        "footer.delivery.title": "Versandkosten",
        "footer.delivery.description": "Der Versand unserer Biere ist kostenlos",
        "footer.cart.link": "Zum Warenkorb",
        "footer.legal.impressum":"Impressum",
        "footer.legal.data":"Datenschutz",
        "detail.share": "Teile dieses Produkt: ",
        "detail.back": "Zurück",
        "detail.forward": "Weiter",
        "summary.back": "Zurück zum Shop",
        "summary.title": "Warenkorb Übersicht",
        "summary.subtotal": "Warenkorb Total:",
        "summary.delivery.costs": "inkl. Porto / Versandkosten",
        "summary.checkout.link": "Zum Checkout",
        "nav.middle.delivery.mail": "Shop",
        "nav.middle.delivery.home": "Heimlieferung",
        "nav.middle.subscription": "Abo",
    };

    let fr = {
        "cart.subtotal": "Sous-Total:",
        "cart.delivery.costs": "Frais d'expédition:",
        "cart.agb.prefix": "Avec la commande, je confirme les ",
        "cart.agb": "termes et conditions",
        "cart.empty": "Il n'y a aucun produit dans le panier.",
        "hero.link": "Voir l'offre",
        "hero.probier.title": "Paquet de variété",
        "hero.probier.description1": "Une sélection de ",
        "hero.probier.description2": "nos variétés",
        "list.product.add": "ajouter",
        "footer.payment.title": "Méthodes de paiement",
        "footer.payment.description": "Nous soutenons les méthodes de paiement suivantes",
        "footer.delivery.title": "Frais d'expédition",
        "footer.delivery.description": "L'expédition de nos bières est gratuite",
        "footer.cart.link": "Au panier",
        "detail.share": "Partager ce produit: ",
        "detail.back": "précédente",
        "detail.forward": "suivante",
        "summary.back": "Retour à la shop",
        "summary.title": "Résumé du panier",
        "summary.subtotal": "Sous-Total de panier:",
        "summary.delivery.costs": "incl. frais de port / d'expéditio",
        "summary.checkout.link": "À la caisse",
    };

    let it = {
        "cart.subtotal": "Subtotale:",
        "cart.delivery.costs": "Costi di spedizione:",
        "cart.agb.prefix": "Con l'ordine confermo i ",
        "cart.agb": "termini e le condizioni",
        "cart.empty": "Non ci sono prodotti nel carrello",
        "hero.link": "Mostra l'offerta",
        "hero.probier.title": "Pacchetto di varietà",
        "hero.probier.description1": "Una selezione di ",
        "hero.probier.description2": "nostre varietà",
        "list.product.add": "aggiungere",
        "footer.payment.title": "Metodi di pagamento",
        "footer.payment.description": "Supportiamo i seguenti metodi di pagamento",
        "footer.delivery.title": "Costi di spedizione",
        "footer.delivery.description": "La spedizione delle nostre birre è gratuita",
        "footer.cart.link": "Al carrello",
        "detail.share": "Condividi questo prodotto: ",
        "detail.back": "precedente",
        "detail.forward": "suivante",
        "summary.back": "Torna al negozio",
        "summary.title": "Riepilogo del carrello",
        "summary.subtotal": "Subototale di carrello:",
        "summary.delivery.costs": "incl. spedizione",
        "summary.checkout.link": "Alla cassa",
    };

    let en = {
        "abo.new.title": "Get a Dr. Brauwolf Subscription",
        "abo.new.text": "Receive the craft beer of the month every month. The size can be changed at any time. Also suitable as a gift.",
        "abo.new.button.subscribe.now": "Subscribe now",
        "abo.new.button.continue.address": "Continue to delivery address",
        "abo.new.button.continue.payment": "Continue to payment",
        "abo.new.button.continue.subscribe": "Subscribe",
        "abo.new.text.month": "month",
        "abo.new.timeframe.cancel.anytime": "Cancellable at any time",
        "abo.new.summary.text.mwst": "incl. VAT",
        "abo.new.summary.text.total": "Total",
        "abo.new.summary.text.save1": "You save ",
        "abo.new.summary.text.save2": " on your first payment",
        "abo.new.summary.button.apply": "Apply",
        "abo.new.summary.text.no.coupons": "No coupons on one-time payments",
        "abo.new.summary.text.coupon.code": "Coupon code",
        "abo.new.navigation.step1.size": "Size",
        "abo.new.navigation.step2.timeframe": "Duration",
        "abo.new.navigation.step3.delivery": "Delivery Address",
        "abo.new.navigation.step4.summary": "Summary",
        "abo.new.navigation.step4.cc": "Please add your credit card details",
        "abo.new.navigation.step4.payment.info": "First payment",
        "abo.new.navigation.step4.payment.info.date": "initial charge date will be ",
        "abo.new.navigation.step4.payment.reduction": " reduction on first payment, ",
        "abo.new.navigation.step4.payment": "Payment",
        "abo.new.timeframe.text.monthly": "Monthly",
        "abo.new.summary.top.title": "Dr. Brauwolf Subscription",
        "abo.new.step2.contact.info": "Contact info",
        "abo.new.step2.delivery.address": "Delivery address",
        "abo.new.step3.delivery": "Delivery",
        "abo.new.step3.change": "change",
        "abo.new.step3.cancellable": "cancellable",
        "abo.link": "Get the Dr. Brauwolf Subscription now!",
        "abo.title": "Dr. Brauwolf Subscription",
        "abo.line1": "Sign up now for the Dr. Brauwolf Subscription and be the first ",
        "abo.line2": "to receive the current Craft Beer of the Month as 6-Pack for only 39.- or in larger sizes (12-Pack / 60.-, 24-Pack / 110.-). The subscription can be cancelled at any time. The delivery list is finalized each 10th of the month.",
        "abo.success":"You have successfully subscribed! The first delivery will be at the beginning of the next month. Your credit card will be charged regularly from now on as long as the subscription is active.",
        "abo.success2":"You have successfully subscribed! The first delivery will be at the beginning of the next month. The subscription will automatically end at the chosen timeframe.",
        "abo.stop":"Please write to info@drbrauwolf.ch or use the login if you want to cancel your subscription",
        "abo.fail":"Subscribing was not successful",
        "abo.again":"Please try again",
        "abo.firstname": "Firstname",
        "abo.lastname": "Lastname",
        "abo.address": "Address",
        "abo.plz": "Postal Code",
        "abo.city": "City",
        "abo.phone": "Phone",
        "abo.size": "Size",
        "abo.button" : "Subscribe",
        "message.delivery": "All deliveries are free",
        "message.discount": "5.- CHF discount for every additional pack",
        "message.discount.tooltip": "only beer & h2op, all variants",
        "message.delivery.special": "Special information on the current situation",
        "message.swissdelivery": "Delivery in Switzerland",
        "message.homedelivery": "Free Home delivery in the Zürich City Area every FR evening",
        "message.homedelivery.tooltip": "Has to be ordered until Fr 14:00",
        "message.homedelivery.delivery1":"The order will be delivered ",
        "message.homedelivery.delivery2":"on friday between 17:00 and 20:00!",
        "message.homedelivery.delivery3":"Has to be ordered until Fr 14:00 and should be collected in person.",
        "message.homedelivery.check.success":"Home Delivery is available in your area!",
        "message.homedelivery.check.fail":"Sorry, Home Delivery is not available in your area. Try the 'Shop' section for a regular delivery.",
        "message.homedelivery.check.info":"Home Delivery is only available in specific areas:",
        "message.homedelivery.check.button":"Check Postal Code",
        "cart.discount": "Discount:",
        "cart.subtotal": "Total:",
        "cart.delivery.costs": "Shipping costs:",
        "cart.agb.prefix": "With the order I confirm the ",
        "cart.agb": "terms & conditions",
        "cart.agb.age": ", including that my age is 18 or over",
        "cart.empty": "There are no products in your shopping cart.",
        "detail.nutrition" : "Note: contains gluten",
        "hero.link": "Show offer",
        "hero.probier.title": "Variety Pack",
        "hero.probier.description1": "A selection of ",
        "hero.probier.description2": "our varieties",
        "list.product.add": "add",
        "list.product.abo": "subscribe",
        "footer.payment.title": "Payment methods",
        "footer.payment.description": "We support the following payment methods",
        "footer.delivery.title": "Shipping costs",
        "footer.delivery.description": "The shipment of our beers is free",
        "footer.cart.link": "Open cart",
        "footer.legal.impressum":"Legal Disclosure",
        "footer.legal.data":"Data Protection",
        "detail.share": "Share this product: ",
        "detail.back": "back",
        "detail.forward": "forward",
        "summary.back": "Back to the shop",
        "summary.title": "Cart summary",
        "summary.subtotal": "Cart Total:",
        "summary.delivery.costs": "incl. postage / shipping costs / discounts",
        "summary.checkout.link": "checkout",
        "hero.product.slogan.guadeloupe-rum-barrel-aged-imperial-stout":"An Imperial Stout conditioned over a year in a Guadeloupe Rum barrel",
        "hero.product.slogan.aronia-sour-rioja-barrel-aged":"An Aronia Berry Sour, barrel-aged in Rioja barrels for no longer than 9 months",
        "hero.product.slogan.winter-ipa":"The sunny days are over and so is the time for light beers",
        "hero.product.slogan.sour-ipa":"A new era has begun for Dr. Brauwolf in the form of super fashionable canned beer",
        "hero.product.slogan.session-ipa":"A beer made for the ones who aren’t ready for winter just yet",
        "hero.product.slogan.rostigraben-2.0":"We all love both potatoes and beer",
        "hero.product.slogan.probier-pack":"A selection of our varieties",
        "hero.product.slogan.beer-tasting-voucher-small-tasting":"Gift on or multiple vouchers for a visit to our brewery",
        "detail.product.title.abo":"Subscription",
        "detail.product.title.probier-pack":"Starter Pack",
        "nav.middle.delivery.mail": "Shop",
        "nav.middle.delivery.home": "Home Delivery",
        "nav.middle.subscription": "Subscription",
        "Vol. untersch.":"Vol. varies",
        "Ver. Grössen":"Different sizes",
        "statt":"instead of"
    };

    let languages = {
        'de-CH' : de,
        'fr-CH' : fr,
        'it-CH' : it,
        'en' : en
    }

    // addLocaleData({
    //       locale: 'de-CH',
    //       messages: de,
    //     pluralRuleFunction(e, t) {
    //         return t && e === 1 ? 'one' : 'other';
    //     }
    //   });
    // addLocaleData({
    //     locale: 'fr-CH',
    //     messages: fr,
    //     pluralRuleFunction(e, t) {
    //         return t && e === 1 ? 'one' : 'other';
    //     }
    // });
    // addLocaleData({
    //     locale: 'it-CH',
    //     messages: it,
    //     pluralRuleFunction(e, t) {
    //         return t && e === 1 ? 'one' : 'other';
    //     }
    // });
    // addLocaleData({
    //     locale: 'en',
    //     messages: en,
    //     pluralRuleFunction(e, t) {
    //         return t && e === 1 ? 'one' : 'other';
    //     }
    // });

    var finalLocale = 'en';

    const cookies = new Cookies();
    if(currentLanguageByParameter)
    {
        if (currentLanguageByParameter === 'de' || currentLanguageByParameter === 'fr' || currentLanguageByParameter === 'it') {
            finalLocale = currentLanguageByParameter + '-CH';
        }
    }
    else if(cookies.get('ls_lang'))
    {
        finalLocale = cookies.get('ls_lang');
    }
    else {

        var locale = require('browser-locale')();
        if (locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0, 2);

            if (localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it') {
                finalLocale = localePrefix + '-CH';
            }
        }
    }

    cookies.set('checkout_locale', finalLocale, {path: '/'});
    cookies.set('ls_lang', finalLocale, {path: '/'});

  return (
      <IntlProvider locale={finalLocale} messages={languages[finalLocale]}>
    <div>
      {/*<div className="content">*/}
          {children}
      {/*</div>*/}
    </div>
      </IntlProvider>
  );
};

App.propTypes = {
  children: PropTypes.node,
};

export default App;