import React, { Component } from 'react';
import slugify from "slugify";
import $ from "jquery";
import {FormattedMessage} from "react-intl";
import VariantSelector from "./VariantSelector";
import Cookies from "universal-cookie";

class ProductDetailInfo extends Component
{
    constructor(props)
    {
        super(props);

        var locale = "en";
        const cookies = new Cookies();
        if(cookies.get('ls_lang'))
        {
            locale = cookies.get('ls_lang').split("-")[0];
        }

        this.state = {
            locale: locale,
            variants: this.props.product.variants.filter(variant => variant.sku != "LUCKABOX"),
            mainOption: this.props.product.options[0],
            mainOptionValues: this.props.product.options[0].values.filter(value => value.value != "Heiml. ZH 24er")
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleOptionChangeBoxes = this.handleOptionChangeBoxes.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    loadOptions()
    {
        const target = event.target;
        let selectedOptions = this.state.selectedOptions;

        selectedOptions[target.name] = target.value;

        const selectedVariant = this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions)

        this.setState({
            selectedVariant: selectedVariant,
            // selectedVariantImage: selectedVariant.attrs.image
        });
    }


    handleOptionChange(event)
    {
        this.props.product.options.forEach((selector) => {
            this.setState({
                selectedOptions: { [selector.name]: selector.values[0].value }
            }, this.loadOptions);
        });
    }

    async handleOptionChangeBoxes(option, name)
    {
        await this.props.product.options.forEach((selector) => {
            this.setState({
                selectedOptions: { [selector.name]: selector.values[0].value }
            }, this.loadOptions);
        });

        let selectedOptions = this.state.selectedOptions;

        selectedOptions[name] = option.value;

        const selectedVariant = await this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions);

        this.setState({
            selectedVariant: selectedVariant,
            selectedVariantImage: selectedVariant.attrs.image
        });
    }

    render() {

        var titleStyle = {
            fontFamily: 'titling',
            fontSize: '36px',
            color: '#000'
        };

        var cartSmall = {
            padding: '0px',
            marginTop: '-3px',
            marginLeft: '-3px'
        }

        let product = null;
        let title = "";
        let description = "";
        let variant = null;
        let variantId = "";
        let urlTitle = "";
        let formattedPrice = "";
        let value1 = "";
        let value2 = "";
        let productType = "";

        let lineItem =  <div className="count-input">
            <a className="incr-btn" data-action="decrease" href="#" onClick={evt => {
                evt.preventDefault();
                // this.decrementQuantity(line_item.id);
            }}>–</a>
            <input className="quantity" type="text" value="0"/>
            <a className="incr-btn" data-action="increase" href="#" onClick={evt => {
                evt.preventDefault();
                this.props.addVariantToCart(product.id, 1);
            }}>+</a>
        </div>;
        let variantSelectors = null;
        if(this.props.product)
        {
            product = this.props.product;
            title = product.title;
            productType = product.productType.toLowerCase();

            variant = this.state.selectedVariant || this.state.variants[0]
            variantId = variant.id;

          formattedPrice = variant.price.amount.toString().replace(".0", ".-");
          value1 = variant.selectedOptions[0].value;
          value2 = variant.selectedOptions[1].value;

            if(this.state.variants.length>1)
            {
                variantSelectors =  <VariantSelector
                    handleOptionChange={this.handleOptionChange}
                    handleOptionChangeBoxes={this.handleOptionChangeBoxes}
                    key={this.props.product.options[0].id.toString()}
                    option={this.props.product.options[0]}
                    values={this.state.mainOptionValues}
                    selected={this.state.selectedVariant}
                />
            }

            urlTitle = slugify(product.title, {
                replacement: '-',    // replace spaces with replacement
                remove: null,        // regex to remove characters
                lower: true          // result in lower case
            });

            lineItem = this.props.checkout.lineItems
                .filter(line_item => line_item.variant.id === variant.id)
                .map((line_item) => {

                    let customId = urlTitle.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,"");

                    return (
                        <div className="count-input" key={line_item.id}>
                            <a className="incr-btn" data-action="decrease" href="#" onClick={evt => {
                                evt.preventDefault();
                                $('#' + customId + '-quantity').val('...');
                                this.props.updateQuantityInCart(line_item.id, line_item.quantity - 1);
                            }}>–</a>
                            <input className="quantity" type="text" value={line_item.quantity} id={customId + '-quantity'}/>
                            <a className="incr-btn" data-action="increase" href="#" onClick={evt => {
                                evt.preventDefault();
                                $('#' + customId + '-quantity').val('...');
                                this.props.updateQuantityInCart(line_item.id, line_item.quantity + 1);
                            }}>+</a>
                        </div>
                    );
                });

            var langDescription = product.description;
            if(langDescription.includes("|"))
            {
                var splitDescription = product.description.split("|");
                if (this.state.locale == "de") {
                    langDescription = splitDescription[1];
                } else if (this.state.locale == "en") {
                    langDescription = splitDescription[0];
                }
            }

            description = <FormattedMessage id={ 'detail.product.description.'+urlTitle } defaultMessage={ langDescription }/>;
        }

        let baseUrl = location.protocol + '//' + location.host;
        let facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=" + baseUrl + "%2F" + urlTitle;
        let twitterUrl = "https://twitter.com/intent/tweet?url=" + baseUrl + "%2F" + urlTitle +"&text=Bestes Craftbeer: ";

        return (
            <section className="fw-section bg-gray padding-bottom-3x">
                <div className="container">
                    <div className="product-info padding-top-2x text-center">
                        {urlTitle == "summer-ale" ?
                            <div style={{display: "flex"}}>
                            <div style={{marginLeft: "auto", marginRight: "auto"}}><h2 style={{color: "orange"}}>Buy 1 Get 1 Free (24er)</h2></div></div> : null
                        }

                        <h1 className="h2 space-bottom-half" style={titleStyle}><FormattedMessage
                            id={'detail.product.title.' + urlTitle} defaultMessage={title}/></h1>
                        {/*<h2>{formattedPrice}</h2>*/}
                        <p className="text-sm text-gray">{description}</p>
                        {productType == "beer" ? <small className="text-sm text-gray"><FormattedMessage id={ 'detail.nutrition'} defaultMessage={ 'Hinweis: Enthält Gluten' }/><br/><br/></small> : null}
                        {/*<div className="product-meta">*/}
                        {/*<div className="product-sku">*/}
                        {/*<strong>SKU: </strong>*/}
                        {/*<span>146950023</span>*/}
                        {/*</div>*/}
                        {/*<div className="product-category">*/}
                        {/*<strong>Category: </strong>*/}
                        {/*<a href="#">Furniture</a>*/}
                        {/*</div>*/}
                        {/*<span className="product-rating text-warning">*/}
              {/*<i className="material-icons star"></i>*/}
              {/*<i className="material-icons star"></i>*/}
              {/*<i className="material-icons star"></i>*/}
              {/*<i className="material-icons star"></i>*/}
              {/*<i className="material-icons star_border"></i>*/}
            {/*</span>*/}
                    {/*</div>*/}
                    <small>{value1} ({value2}) - CHF {formattedPrice}</small>
                    <div className="product-tools shop-item">
                        {/*<div className="count-input">*/}
                            {/*<a className="incr-btn" data-action="decrease" href="#">–</a>*/}
                            {/*<input className="quantity" type="text" value="1"/>*/}
                            {/*<a className="incr-btn" data-action="increase" href="#">+</a>*/}
                            {/*</div>*/}
                            {lineItem}
                            {/*<div className="form-element">*/}
                            {/*<select className="form-control form-control-sm color-select">*/}
                            {/*<option value="blue" data-image="preview02">Blue</option>*/}
                            {/*<option value="creme" data-image="preview01">Creme</option>*/}
                            {/*<option value="red" data-image="preview03">Red</option>*/}
                            {/*</select>*/}
                            {/*</div>*/}
                            {variantSelectors}
                            <a href="#" className="add-to-cart buy" onClick={evt => {
                                evt.preventDefault();
                                this.props.addVariantToCart(variantId, 1);
                            }}>
                                <em><span className="material-icons shopping_basket" style={cartSmall}></span> <FormattedMessage
                                    id={ 'list.product.add' }
                                    defaultMessage={ 'zufügen' }
                                    values={{ name }}
                                /></em>
                                <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                                    <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"></path>
                                </svg>
                            </a>
                        </div>
                        <div className="product-share">
                            <span><FormattedMessage id={ 'detail.share' } defaultMessage={ 'Teile dieses Produkt: ' }/></span>
                            <div className="social-bar">
                                <a href={facebookUrl} className="sb-facebook" data-toggle="tooltip" data-placement="top" title="" data-original-title="Facebook">
                                    <i className="socicon-facebook"></i>
                                </a>
                                <a href={twitterUrl} className="sb-twitter" data-toggle="tooltip" data-placement="top" title="" data-original-title="Twitter">
                                    <i className="socicon-twitter"></i>
                                </a>
                            </div>
                        </div>
                        {/*<br/><p><a href='/'>Zurück zur Übersicht</a></p>*/}
                    </div>
                </div>
            </section>
        );
    }
}

export default ProductDetailInfo;