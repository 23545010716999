/* eslint react/jsx-max-props-per-line: 0 */
/* eslint react/jsx-sort-props: 0 */ 
import React from 'react';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import history from "./history.js";

import App from './App';
import Home from './Home';
import Heimlieferung from './Heimlieferung';
import Detail from './Detail';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import Summary from "./Summary";
import Abo from "./Abo";
import Express from "./Express";
import User from "./User";
import UserManagement from "./UserManagement";

const Root = (props) => {
  return (
    <Router history={history}>
      <App>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/abo" component={Abo} />
          <Route exact path="/express" component={Express} />
          <Route exact path="/abo-success" render={(props) => <Abo result="success" {...props} /> } />
          <Route exact path="/abo-cancelled" render={(props) => <Abo result="failed" {...props} /> } />
          <Route exact path="/cart" component={Summary} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/datenschutz" component={Datenschutz} />
          <Route exact path="/heimlieferung" component={Heimlieferung} />
          <Route exact path="/login" component={User} />
          <Route exact path="/user" component={UserManagement} />
          <Route path="/:id" component={Detail} />
          <Route path="*" component={Home} />
        </Switch>
      </App>
    </Router>
  );
};

export default Root;
