import React, { Component } from 'react';
import Header from './Header';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import Cookies from "universal-cookie";
import Footer from "./Footer";
import {FormattedMessage} from "react-intl";
import VatiaCheckout from "./vatia/VatiaCheckout";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

const backendUrl = "https://sub.vatia.io";


class Abo extends Component
{
    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
            footer: true,
            // lang: locale
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
        this.toggleFooterState = this.toggleFooterState.bind(this);

        this.openCheckout = this.openCheckout.bind(this);
    }

    toggleFooterState()
    {
        this.setState({footer: !this.state.footer})
    }

    openCheckout()
    {
        window.open(this.props.checkout.webUrl);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            console.log("creating new checkout id");
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    console.log("checkout is now invalid");
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            // var filteredProducts = [];
            // unfilteredProducts.forEach(product => {
            //     if(product.tags != null && product.tags.length > 0) {
            //         var tags = product.tags.map(tag => tag.value);
            //         if (!tags.includes("deactivated")) {
            //             filteredProducts.push(product);
            //         }
            //     }
            // });
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        // var finalLocale = 'en';
        // var locale = require('browser-locale')();
        // if(locale == null) {
        //     //console.log('no locale');
        // }
        // else {
        //     var localePrefix = locale.substring(0,2);
        //
        //     if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
        //     {
        //         finalLocale = localePrefix + '-CH';
        //     }
        // }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            var animating = false;

            var addToCartBtn = $(this).find('.add-to-cart');

            if(!animating) {
                //animate if not already animating
                animating =  true;
                // resetCustomization(addToCartBtn);

                addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                    //draw the check icon
                    'stroke-dashoffset':0
                }, 300, function(){
                    setTimeout(function(){
                        // updateCart();
                        addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                            //wait for the end of the transition to reset the check icon
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        });

                        if( $('.no-csstransitions').length > 0 ) {
                            // check if browser doesn't support css transitions
                            addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                            animating =  false;
                        }
                    }, 600);
                });
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
        const checkoutId = this.state.checkout.id

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    render()
    {
        let content = (<div style={{textAlign: "center", paddingTop:40}}>
            <h1 style={{fontSize:36, marginBottom:10}}>
                {/*<Typography sx={{ mt: 2, mb: 1, py: 1 }} style={{marginTop:0}}>*/}
                    <FormattedMessage id={ 'abo.new.title' } defaultMessage={ 'Das Dr. Brauwolf Abo' }/>
                {/*</Typography>*/}
            </h1>
            <p style={{fontWeight:300, color:"grey", fontSize:17, marginBottom:40, paddingLeft:20, paddingRight:20}}>
                <span style={{maxWidth:450, display:"inline-block"}}>
                    <FormattedMessage id={ 'abo.new.text' } defaultMessage={ 'Erhalte jeden Monat das aktuelle Craft Beer of the Month. Die Grösse ist jederzeit änderbar. Auch als Geschenk geeignet.'} />
                </span>
           </p>
            <iframe frameBorder="0" border="0" cellSpacing="0" scrolling="no"
                    style={{borderStyle: "none", width: "100%", height: "120vh", overflow:"hidden"}}
                    src={backendUrl+"/checkout"}></iframe></div>)

        if(this.props.result == "success")
        {
            content = (
                <div className="padding-top-2x text-center">
                    <h1><FormattedMessage id={ 'abo.title' } defaultMessage={ 'Dr. Brauwolf Abo' }/></h1>
                    <p><FormattedMessage id={ 'abo.success' } defaultMessage={ 'Erfolgreich abonniert. Die erste Lieferung kommt Anfang nächsten Monats. Deine Kreditkarte wird von nun an regelmässig belastet solange das Abo aktiv ist.' }/><br/><br/>
                        <FormattedMessage id={ 'abo.stop' } defaultMessage={ 'Schreibe uns ein Mail an info@drbrauwolf.ch wenn du das Abo ändern oder künden möchtest.'}/></p>
                </div>)
        }

        if(this.props.result == "failed")
        {
            content = (
                <div className="padding-top-2x text-center">
                    <h1><FormattedMessage id={ 'abo.title' } defaultMessage={ 'Das Dr. Brauwolf Abo' }/></h1>
                    <p><FormattedMessage id={ 'abo.fail' } defaultMessage={ 'Abonnieren war nicht erfolgreich.'}/> <a href="/abo"><FormattedMessage id={ 'abo.title' } defaultMessage={ 'Versuche es nochmals'}/></a></p>
                </div>)
        }

        return (
            <div className="App">
                <div className="page-wrapper">
                    {true == true ?<Header
                          products={this.state.products}
                          checkout={this.state.checkout}
                          isCartOpen={this.state.isCartOpen}
                          handleCartClose={this.handleCartClose}
                          updateQuantityInCart={this.updateQuantityInCart}
                          removeLineItemInCart={this.removeLineItemInCart}
                      /> : null}
                    <section className="fw-section bg-white">
                        {content}
                    </section>
                </div>
                {this.state.footer == true ? <Footer/> : null}
            </div>
        );
    }
}

export default withRouter(Abo)
