import React, {Component} from 'react';
import slugify from 'slugify';
import $ from "jquery";

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {

      let url = slugify(this.props.line_item.title, {
          replacement: '-',    // replace spaces with replacement
          remove: null,        // regex to remove characters
          lower: true          // result in lower case
      });

      let customId = url.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,"");
      let customId2 = customId+"2";

      var titleStyle= {
          fontFamily: 'titling',
          fontSize: '18px',
          color: '#000'
      };

    return (
        <div className="cart-item">
          <a href="" className="item-thumb">
              {this.props.line_item.variant.image ? <img src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`}/> : null}
          </a>
          <div className="item-details">
            <h3 className="item-title"><a style={titleStyle} href={url}>{this.props.line_item.title}</a> <small>({this.props.line_item.variant.selectedOptions[0].value})</small></h3>
            {/*<h4 className="item-price">{this.props.line_item.quantity} x $ { (this.props.line_item.quantity * this.props.line_item.variant.price).toFixed(2) }</h4>*/}
            {/*<div className="Line-item__quantity-container">*/}
              {/*<button className="Line-item__quantity-update" onClick={() => this.decrementQuantity(this.props.line_item.id)}>-</button>*/}
              {/*<span className="Line-item__quantity">{this.props.line_item.quantity}</span>*/}
              {/*<button className="Line-item__quantity-update" onClick={() => this.incrementQuantity(this.props.line_item.id)}>+</button>*/}
            {/*</div>*/}
            <div className="count-input">
                <a className="incr-btn" data-action="decrease" href="#" onClick={evt => {
                    evt.preventDefault();
                    $('#' + customId + '-quantity').val('...');
                    $('#' + customId2 + '-quantity').val('...');
                    this.decrementQuantity(this.props.line_item.id);
                }}>–</a>
                <input className="quantity" type="text" value={this.props.line_item.quantity} id={customId2 + '-quantity'}/>
                <a className="incr-btn" data-action="increase" href="#" onClick={evt => {
                    evt.preventDefault();
                    $('#' + customId + '-quantity').val('...');
                    $('#' + customId2 + '-quantity').val('...');
                    this.incrementQuantity(this.props.line_item.id);
                }}>+</a>
            </div>
          </div>
          <a href="#" className="close-btn" onClick={evt => {
              evt.preventDefault();
              this.props.removeLineItemInCart(this.props.line_item.id);
          }}>
            <i className="material-icons close"></i>
          </a>
      </div>
    );
  }
}

export default LineItem;
