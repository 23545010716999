import React, {Component} from 'react';
import Client from 'shopify-buy';
import slugify from 'slugify';
import Media from "react-media";
import {IntlProvider, FormattedMessage} from 'react-intl';
import $ from "jquery";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import VariantSelector from "./VariantSelector"; // ES6

class ProductHeimlieferung extends Component {
  constructor(props) {
    super(props);

    this.state = {
        variants: this.props.product.variants.filter(variant => variant.sku == "LUCKABOX"),
        mainOption: this.props.product.options[0],
        mainOptionValues: this.props.product.options[0].values.filter(value => value.value == "Heiml. ZH 24er")
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
    // this.filterVariants = this.filterVariants.bind(this);

    // this.filterVariants(this.props.product.variants, this.props.product.options[0]);
  }

  // filterVariants(variants, mainOption)
  // {
  //     console.log(mainOption);
  //
  //   var filteredVariants = variants.filter(variant => variant.sku == "LUCKABOX");
  //   var filteredValues = mainOption.values.filter(value => value.value == "Heiml. ZH 24er");
  //   this.setState({variants: filteredVariants, mainOptionValues: filteredValues});
  // }

  componentWillMount() {
    this.props.product.options.forEach((selector) => {
      this.setState({
        selectedOptions: { [selector.name]: selector.values[0].value }
      });
    });
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  async handleOptionChange(event)
  {
    const target = event.target;
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = await this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions);

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

    decrementQuantity(lineItem) {
        const updatedQuantity = lineItem.quantity - 1;
        this.props.updateQuantityInCart(lineItem.id, updatedQuantity);
    }

    incrementQuantity(lineItem) {
        const updatedQuantity = lineItem.quantity + 1;
        this.props.updateQuantityInCart(lineItem.id, updatedQuantity);
    }

  render() {

      if(this.state.variants == null || this.state.variants.length <= 0)
      {
          return <span></span>
      }

      let variantImage = this.state.selectedVariantImage || this.props.product.images[0];
      let variantImage2 = this.state.selectedVariantImage || this.props.product.images[1];
      let variantImage3 = this.state.selectedVariantImage || this.props.product.images[2];
      let variantImage4 = this.state.selectedVariantImage || this.props.product.images[3];
      let variant = this.state.selectedVariant || this.state.variants[0];
      let variantQuantity = this.state.selectedVariantQuantity || 1;

      var quantityStyle = {
          // position: 'relative',
          margin: '0px',
          padding: '0px',
          width: '114px',
          // marginLeft:'16px'
          display: "inline-block"
      };

      var titleStyle= {
        fontFamily: 'titling',
        fontSize: '20px',
        color: '#000',
        maxLines: 3
      };

      var lineStyle= {
          borderBottom: '2px solid #ededed'
      }

      var cartSmall = {
          padding: '0px',
          marginTop: '-3px',
          marginLeft: '-3px'
      }

      let separationLine = <Media
          query="(max-width: 768px)"
          render={() => <div style={lineStyle}/>}
      />

      let url = slugify(this.props.product.title, {
          replacement: '-',    // replace spaces with replacement
          remove: null,        // regex to remove characters
          lower: true          // result in lower case
      });

      let lineItem = this.props.checkout.lineItems
          .filter(line_item => line_item.variant.id === variant.id)
          .map((line_item) => {

              let customId = url.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,"");

              return (
                  <div className="count-input" style={quantityStyle} key={line_item.id}>
                      <a className="incr-btn" data-action="decrease" href="#" onClick={evt => {
                          evt.preventDefault();
                          $('#' + customId + '-quantity').val('...');
                          this.decrementQuantity(line_item);
                      }}>–</a>
                      <input className="quantity" type="text" value={line_item.quantity} id={customId + '-quantity'}/>
                      <a className="incr-btn" data-action="increase" href="#" onClick={evt => {
                          evt.preventDefault();
                          $('#' + customId + '-quantity').val('...');
                          this.incrementQuantity(line_item);
                      }}>+</a>
                  </div>
              );
          });

     if(lineItem.length === 0)
     {
       lineItem =  <span><a className="add-to-cart" href="#" onClick={evt => {
               evt.preventDefault();
               this.props.addVariantToCart(variant.id, variantQuantity);
           }}>
                <em><span className="material-icons shopping_basket" style={cartSmall}></span>
                    &nbsp;&nbsp;<FormattedMessage
                          id={ 'list.product.add' }
                          defaultMessage={ 'zufügen' }
                          values={{ name }}
                      />
                </em>
               <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                   <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"></path>
               </svg></a><br/></span>
     }

      if("Abo" === this.props.product.title)
      {
          lineItem = (<span><a className="add-to-cart" href="/abo">
                <em><span className="material-icons shopping_basket" style={cartSmall}></span>
                    &nbsp;&nbsp;<FormattedMessage
                        id={ 'list.product.abo' }
                        defaultMessage={ 'Anmelden' }
                        values={{ name }}
                    />
                </em>
               <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                   <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"></path>
               </svg></a><br/></span>);
      }

     let formattedPrice = variant.price.amount.toString().replace(".0", ".-");
      let srcset = null;
      if(variantImage != null && variantImage2 != null && variantImage3 != null && variantImage4 != null)
      {
          srcset = variantImage.src + " 723w," + variantImage2.src + " 345w," + variantImage3.src + " 213w," + variantImage4.src + " 263w";
      }
      else if(variantImage != null)
      {
          srcset = variantImage.src + " 723w," + variantImage.src + " 345w," + variantImage.src + " 213w," + variantImage.src + " 263w";
      }

     let specialInfo = "";
     for(var i=0; i < variant.selectedOptions.length ; i++)
     {
         if (variant.selectedOptions[i].name == "Info")
         {
             specialInfo = <span className="shop-label" style={{
                 backgroundColor: "#ededed",
                 left: "5px"
             }}>{variant.selectedOptions[i].value}</span>
         }
     }

    let variantSelectors = null
     if(this.state.variants.length>1)
     {
        variantSelectors =  <span><VariantSelector
             handleOptionChange={this.handleOptionChange}
             key={this.state.mainOption.id.toString()}
             option={this.state.mainOption}
             values={this.state.mainOptionValues}
             selected={this.state.selectedVariant}
         /><br/></span>
     }
     else
     {
         let singleValue = this.state.mainOptionValues[0].value;
         variantSelectors =  <div><span style={{height: 35,paddingTop:4,display:"inline-block", fontSize:14}}>{singleValue}<br/></span><br/></div>
     }

    return (
        <ReactCSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}>
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
        <div className="shop-item" id={variant.id} key={variant.id}>
          <div className="shop-thumbnail">
          {specialInfo}
          <a href={null} aria-label={url} className="item-link"></a>
            {this.props.product.images.length ? <img style={{maxHeight:332}}
                                                     src={variantImage.src}
                                                     srcSet={srcset}
                                                     sizes="(max-width: 768px) 345px, (min-width: 768px) 345px, (min-width: 992px) 213px, (min-width: 1200px) 263px"
                                                     alt={`${this.props.product.title} product shot`}/> : null}
            <div className="shop-item-tools">
            </div>
          </div>
            <span className="visible-sm visible-lg visible-md">
              <div className="shop-item-details">
                <h3 className="shop-item-title" style={{width:"95%"}}>
                    <a href={null} style={titleStyle}>
                        <FormattedMessage id={ 'detail.product.title.'+url} defaultMessage={ this.props.product.title }/>
                    </a>
                    <br/><small>CHF {formattedPrice}</small>
                    <br/><small><i style={{fontSize:'11px'}}>{variant.selectedOptions[1].value}</i></small>
                </h3>
                <span className="shop-item-price">
                    {variantSelectors}
                    {lineItem}
                    {/*<small>{variant.selectedOptions[1].value}</small>*/}
                </span>
            </div>
            </span>
            <span className="visible-xs" style={{minHeight: 196, height: 196}}>
                <a href={null} style={titleStyle}>
                    <FormattedMessage id={ 'detail.product.title.'+url} defaultMessage={ this.props.product.title }/>
                </a>
                <br/><small style={{margin:0}}>CHF {formattedPrice}</small>
                <br/><div style={{fontSize:'11px', padding:0, margin:0, marginBottom:"-15px"}}>
                    <small ><i>{variant.selectedOptions[1].value}</i></small>
                </div>
                <br/>{variantSelectors}
                {lineItem}
            </span>
      </div>
          {/*{separationLine}*/}
    </div>
        </ReactCSSTransitionGroup>
    );
  }
}

export default ProductHeimlieferung;
