import React, {Component} from 'react';
import LineItem from './LineItem';
import Cookies from "universal-cookie";
import {FormattedMessage} from "react-intl";
import Client from "shopify-buy";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Cart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkout: this.props.checkout,
            discount: 0,
            discounting: false,
            checkDiscount: false
        };

        this.openCheckout = this.openCheckout.bind(this);
        this.updateCheckout = this.updateCheckout.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.checkout !== this.props.checkout) {
            this.setState({checkout: this.props.checkout});
        }
    }

    openCheckout() {
        const cookies = new Cookies();
        if(cookies.get('ls_lang'))
        {
            window.open(this.state.checkout.webUrl+'&locale='+cookies.get('ls_lang'));
        }
        else
        {
            window.open(this.state.checkout.webUrl);
        }
    }

    updateCheckout(checkout) {
        this.setState({checkout:checkout, discount:0, discounting:false});
    }

    updateDiscountCheck(check) {
        this.setState({checkDiscount: check});
    }

    render() {

        let currentLocale = 'EN';
        const cookies = new Cookies();
        if(cookies.get('ls_lang'))
        {
            currentLocale = cookies.get('ls_lang').substring(0,2).toUpperCase();
        }

        let mainImage = 'img/flags/' + currentLocale + '.png';

        // let allLanguages = ["DE", "FR", "IT", "EN"];
        let allLanguages = ["DE", "EN"];
        let index = allLanguages.indexOf(currentLocale);

        allLanguages.splice(index,1);

        let lang1 = allLanguages[0];
        // let lang2 = allLanguages[1];
        // let lang3 = allLanguages[2];

        let basicLink = '?lang=';
        let lang1Link = basicLink + lang1.toLowerCase();
        // let lang2Link = basicLink + lang2.toLowerCase();
        // let lang3Link = basicLink + lang3.toLowerCase();

        let basicImg = 'img/flags/';
        let endImg = '.png';
        let lang1Img = basicImg + lang1 + endImg;
        // let lang2Img = basicImg + lang2 + endImg;
        // let lang3Img = basicImg + lang3 + endImg;

        let formattedPrice = '0.00';
        let formattedDiscount = '0.00';
        let discount = 0;

        let count = this.state.checkout.lineItems
            .filter((line_item) => line_item.title != "Mini Keg" && !line_item.title.includes("Voucher") && !line_item.title.includes("T-Shirt"))
            .map((line_item) => {return line_item.quantity;})
            .reduce(function(a,b) {return a+b;},0);

        let discountCode = "";
        if(count <= 1)
        {
            if(this.state.checkDiscount == true)
            {
                //Removes the applied discount from an existing checkout.
                client.checkout.removeDiscount(this.state.checkout.id).then(checkout => {
                    this.updateCheckout(checkout);
                    this.updateDiscountCheck(false);
                });
            }
        }
        else if(count == 2)
        {
            discountCode = 'DM7A2CR4DFP4';
            formattedDiscount = '5.00';
            discount = 5;
        }
        else if(count == 3)
        {
            discountCode = 'R86XDV0Z6S68';
            formattedDiscount = '10.00';
            discount = 10;
        }
        else if(count == 4)
        {
            discountCode = 'N7GYCT233BEY';
            formattedDiscount = '15.00';
            discount = 15;
        }
        else if(count == 5)
        {
            discountCode = 'D7JYFRYS6GSS';
            formattedDiscount = '20.00';
            discount = 20;
        }
        else if(count == 6)
        {
            discountCode = 'NT0TBZERT5CM';
            formattedDiscount = '25.00';
            discount = 25;
        }
        else if(count == 7)
        {
            discountCode = '4WPS75G6NB9B';
            formattedDiscount = '30.00';
            discount = 30;
        }
        else if(count == 8)
        {
            discountCode = 'SVKFT3FANW7F';
            formattedDiscount = '35.00';
            discount = 35;
        }
        else if(count == 9)
        {
            discountCode = 'DTWFKSE3DSQX';
            formattedDiscount = '40.00';
            discount = 40;
        }
        else if(count == 10)
        {
            discountCode = '5D46P024S08F';
            formattedDiscount = '45.00';
            discount = 45;
        }
        else if (count > 10)
        {
            discountCode = '5D46P024S08F';
            formattedDiscount = '45.00';
            discount = 45;
        }

        let finalDiscount = 0;
        if(discountCode != "" && this.state.checkout.discountApplications)
        {
            if (this.state.checkout.discountApplications.length == 0 && count > 1)
            {
                finalDiscount = 5;
                // this.setState({discounting: true});
                client.checkout.addDiscount(this.state.checkout.id, discountCode).then(checkout =>
                {
                    // console.log("added new discount", discountCode);
                    this.updateCheckout(checkout);
                    this.updateDiscountCheck(true);
                });
            }
            else
            {
                if(this.state.checkout.discountApplications[0] != null && this.state.checkout.discountApplications[0].code != discountCode)
                {
                    finalDiscount = 5;
                    // this.setState({discounting: true});
                    client.checkout.addDiscount(this.state.checkout.id, discountCode).then(checkout =>
                    {
                        // console.log("added new discount", discountCode);
                        this.updateCheckout(checkout);
                        this.updateDiscountCheck(true);
                    });
                }
            }
        }

      let checkoutButton = null;
      if(this.props.checkout && this.state.checkout.subtotalPrice && this.state.checkout.subtotalPrice.amount > 0)
      {
          if(this.state.discounting)
          {
              formattedPrice = ".. calculating";
          }
          else
          {
              formattedPrice = (this.state.checkout.subtotalPrice.amount-finalDiscount).toString().replace(".00", ".-");
          }

            checkoutButton = <span>
              <div className="cart-subtotal">
                  <div className="column">
                      <span><FormattedMessage id={ 'cart.delivery.costs' } defaultMessage={ 'Versandkosten:' }/></span>
                  </div>
                  <div className="column">
                      <span className="amount"> 0.00</span>
                  </div>
              </div>
             <div className="cart-subtotal">
                <div className="column">
                  <span><FormattedMessage id={ 'cart.discount' } defaultMessage={ 'Rabatt:' }/></span>
                </div>
                <div className="column">
                  <span className="amount">-{formattedDiscount}</span>
                </div>
              </div>
               <div className="cart-subtotal">
                <div className="column">
                  <span><FormattedMessage id={ 'cart.subtotal' } defaultMessage={ 'Total:' }/></span>
                </div>
                <div className="column">
                  <span className="amount">CHF {formattedPrice}</span>
                </div>
              </div>
              <br/><a href="#" className="add-to-cart" onClick={evt => {
                evt.preventDefault();
                this.openCheckout();
            }}>
              <em>Checkout</em>
              <svg x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32">
                  <path strokeDasharray="19.79 19.79" strokeDashoffset="19.79" fill="none" stroke="#FFFFFF"
                        strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" d="M9,17l3.9,3.9c0.1,0.1,0.2,0.1,0.3,0L23,11"/>
              </svg>
          </a>
          <br/><br/>
              <small>
                  <FormattedMessage id={ 'cart.agb.prefix' } defaultMessage={ "Mit der Bestellung bestätige ich die " }/>
                  <a target='_blank' href='/pdf/AGB.pdf'><FormattedMessage id={ 'cart.agb' } defaultMessage={ "AGB" }/></a>
                  <FormattedMessage id={ 'cart.agb.age' } defaultMessage={ " und damit, dass ich mind. 18 Jahre alt bin" }/>.
              </small>
          </span>
        }
        else
        {
            checkoutButton = <small><FormattedMessage id={ 'cart.empty' } defaultMessage={ 'Es befinden sich keine Produkte im Warenkorb.' }/></small>
        }

        let variantIds = [];
        this.props.products.forEach(product =>
        {
            // if(null != product.productType && "" != product.productType)
            // {
                product.variants.forEach(variant => {
                    variantIds.push(variant.id);
                })
            // }
        })

        let line_items = this.state.checkout.lineItems.map((line_item) =>
        {
            let productId = line_item.variant.id;
            let productAvailable = variantIds.includes(productId);
            if(null != this.props.products && this.props.products.length > 0 && (null == productAvailable || "" == productAvailable || false == productAvailable))
            {
                this.props.removeLineItemInCart(line_item.id);
                console.log(line_item.title + "in cart is currently not available - removed from cart");
            }

            return (
                <LineItem
                    updateQuantityInCart={this.props.updateQuantityInCart}
                    removeLineItemInCart={this.props.removeLineItemInCart}
                    key={line_item.id.toString()}
                    line_item={line_item}
                />
            );
        });

        let itemsInCart = '..';
        if(this.state.checkout.lineItems) {
            itemsInCart = this.state.checkout.lineItems.map((line_item) => line_item.quantity).reduce((a, b) => a + b, 0)
        }

        return (
            <div className="toolbar" style={{width:250}}>
                <div className="inner">
                    <a className="mobile-menu-toggle"></a>
                    <div style={{display:'table-cell', verticalAlign:"middle"}}>
                        <a style={{color:"white", textDecoration:"none"}} href="/login">Login</a>
                    </div>
                    <div className="lang-switcher" style={{display:'table-cell', verticalAlign:"middle", position:"relative", paddingLeft:15}}>
                        <div className="lang-toggle">
                            <img src={mainImage} alt={currentLocale}/>
                            <i className="material-icons arrow_drop_down"></i>
                            <ul className="lang-dropdown">
                                <li><a href={lang1Link}><img src={lang1Img} alt={lang1}/>{lang1}</a></li>
                                {/*<li><a href={lang2Link}><img src={lang2Img} alt={lang2}/>{lang2}</a></li>*/}
                                {/*<li><a href={lang3Link}><img src={lang3Img} alt={lang3}/>{lang3}</a></li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className="cart-btn" style={{}}>
                        <a href="cart">
                            <i>
                                <span className="material-icons shopping_basket"></span>
                                <span className="count">{itemsInCart}</span>
                            </i>
                        </a>
                        <div className="cart-dropdown">
                            {line_items}
                            {checkoutButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart;