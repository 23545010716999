import React, {Component} from 'react';

class VariantSelector extends Component {


  render()
  {
      var dropdownStyle = {
          marginTop: '3px',
          marginBottom: '5px',
          textIndent: '5px',
          top: '5px',
          minWidth: '114px',
          backgroundColor: '#f5f5f5',
          fontColor: 'grey',
          height: 26
      };

      if(this.props.values.length <= 3)
      {
          return (<div style={{marginBottom:5}}>
                  {this.props.values.map((value, index) => {
                      if (this.props.selected && this.props.selected.selectedOptions[0].value == value) {
                          return <button key={index} onClick={() => this.props.handleOptionChangeBoxes(value, this.props.option.name)} style={{minWidth: 37, border:"2px solid black", backgroundColor:"white",marginRight:1}}>{value.value.replace(" x 33cl", "").replace("Grösse ", "")}</button>
                      }
                      else if(this.props.selected && this.props.selected.selectedOptions[0].value != value) {
                          return <button key={index} onClick={() => this.props.handleOptionChangeBoxes(value, this.props.option.name)} style={{minWidth: 37, border:"1px solid #999", backgroundColor:"white",marginRight:1}}>{value.value.replace(" x 33cl", "").replace("Grösse ", "")}</button>
                      }
                      else {
                          return <button key={index} onClick={() => this.props.handleOptionChangeBoxes(value, this.props.option.name)} style={{minWidth: 37, border:index==0?"2px solid black":"1px solid #999", backgroundColor:"white",marginRight:1}}>{value.value.replace(" x 33cl", "").replace("Grösse ", "")}</button>
                      }
                  })}
          </div>);
      }

      if(this.props.selected) {
          return (
              <select aria-label={this.props.option.name}
                      style={dropdownStyle}
                      className="Product__option"
                      name={this.props.option.name}
                      key={this.props.option.name}
                      onChange={this.props.handleOptionChange}
              >
                  {this.props.values.map((value) =>
                  {
                      if (this.props.selected.selectedOptions[0].value == value)
                      {
                          return (
                              <option aria-label={value} value={value} key={`${this.props.option.name}-${value}`}
                                      selected="selected">{`${value}`}</option>
                          )
                      }

                      return (
                          <option value={value} key={`${this.props.option.name}-${value}`}>{`${value}`}</option>
                      )
                  })}
              </select>
          );
      }
      else {
          return ( <select aria-label={this.props.option.name}
                           style={dropdownStyle}
                           className="Product__option"
                           name={this.props.option.name}
                           key={this.props.option.name}
                           onChange={this.props.handleOptionChange}
          >
              {this.props.values.map((value) => {
                  return (
                      <option value={value} key={`${this.props.option.name}-${value}`}>{`${value}`}</option>
                  )
              })}
          </select>)
      }
  }
}

export default VariantSelector;
