import React, { Component } from 'react';
import ProductsHeimlieferung from './ProductsHeimlieferung';
import Header from './Header';
import Footer from './Footer';
import Client from "shopify-buy";
import {withRouter} from 'react-router';
import $ from 'jquery';
import Cookies from 'universal-cookie';
import {FormattedMessage} from "react-intl";

export const client = Client.buildClient({
    storefrontAccessToken: '8ca8cb5f4a81756952c5994d52462f2c',
    domain: 'dr-brauwolf.myshopify.com',
});

class Heimlieferung extends Component {

    constructor() {
        super();

        this.state = {
            isCartOpen: false,
            checkout: { lineItems: [] },
            products: [],
            shop: {},
            heroContent: '',
            plz: null,
            plzIsValidated: false,
            plzIsNotAllowed: false,
        };

        this.handleCartClose = this.handleCartClose.bind(this);
        this.addVariantToCart = this.addVariantToCart.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
        this.handlePlzChange = this.handlePlzChange.bind(this);
        this.checkPlz = this.checkPlz.bind(this);
    }

    componentWillMount()
    {
        if(!localStorage.getItem('checkoutId'))
        {
            client.checkout.create().then((res) => {
                this.setState({
                    checkout: res,
                });
                // setter
                localStorage.setItem('checkoutId', res.id);
            });
        }
        else
        {
            const checkoutId = localStorage.getItem('checkoutId');
            client.checkout.fetch(checkoutId).then((res) => {

                if(res == null || res.order)
                {
                    localStorage.clear();
                    client.checkout.create().then((res) => {
                        this.setState({
                            checkout: res,
                        });
                        // setter
                        localStorage.setItem('checkoutId', res.id);
                    });

                }
                else {
                    this.setState({
                        checkout: res,
                    });
                }
            });
        }

        client.collection.fetchAllWithProducts().then((collections) => {

            var collectionAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Regular")[0].products;
            var collectionNoAlcohol =  collections.filter(c => c.title == "Dr. Brauwolf - Alkoholfrei")[0].products;
            var collectionSpecial =  collections.filter(c => c.title == "Dr. Brauwolf - Beer of the Month")[0].products;
            var collectionShirts =  collections.filter(c => c.title == "Dr. Brauwolf - Sonstiges")[0].products;
            var collectionGiftcard = collections.filter(c => c.title == "Dr. Brauwolf - Geschenkkarte")[0].products;

            var unfilteredProducts = collectionAlcohol.concat(collectionSpecial).concat(collectionNoAlcohol).concat(collectionShirts).concat(collectionGiftcard);
            this.setState({
                products: unfilteredProducts,
            });
        });

        client.shop.fetchInfo().then((res) => {
            this.setState({
                shop: res,
            });
        });

        var finalLocale = 'en';
        var locale = require('browser-locale')();
        if(locale == null) {
            //console.log('no locale');
        }
        else {
            var localePrefix = locale.substring(0,2);
            this.setState({lang: localePrefix});

            if(localePrefix === 'de' || localePrefix === 'fr' || localePrefix === 'it')
            {
                finalLocale = localePrefix + '-CH';
            }
        }

        const cookies = new Cookies();
        if(!cookies.get('ls_lang'))
        {
            cookies.set('checkout_locale', finalLocale, {path: '/'});
            cookies.set('ls_lang', finalLocale, {path: '/'});
        }
    }

    handleScriptCreate() {
        this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
        this.setState({ scriptError: true })
    }

    handleScriptLoad() {
        this.setState({ scriptLoaded: true })
    }

    addVariantToCart(variantId, quantity){

        // Add to Cart Button Effect
        //------------------------------------------------------------------------------
        $('.shop-item').each(function() {

            if(this.id === variantId)
            {
                var animating = false;

                var addToCartBtn = $(this).find('.add-to-cart');

                if(!animating) {
                    //animate if not already animating
                    animating =  true;
                    // resetCustomization(addToCartBtn);
                    addToCartBtn.addClass('is-added').find('path').eq(0).animate({
                        //draw the check icon
                        'stroke-dashoffset':0
                    }, 300, function(){
                        setTimeout(function(){
                            // updateCart();
                            addToCartBtn.removeClass('is-added').find('em').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                                //wait for the end of the transition to reset the check icon
                                addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                                animating =  false;
                            });

                            if( $('.no-csstransitions').length > 0 ) {
                                // check if browser doesn't support css transitions
                                addToCartBtn.find('path').eq(0).css('stroke-dashoffset', '19.79');
                                animating =  false;
                            }
                        }, 600);
                    });
                }
            }
        });

        this.setState({
            isCartOpen: true,
        });

        const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}];
        const checkoutId = this.state.checkout.id;

        return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    updateQuantityInCart(lineItemId, quantity) {
        const checkoutId = this.state.checkout.id
        const lineItemsToUpdate = [{id: lineItemId, quantity: parseInt(quantity, 10)}]

        return client.checkout.updateLineItems(checkoutId, lineItemsToUpdate).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    removeLineItemInCart(lineItemId) {
        const checkoutId = this.state.checkout.id

        return client.checkout.removeLineItems(checkoutId, [lineItemId]).then(res => {
            this.setState({
                checkout: res,
            });
        });
    }

    handleCartClose() {
        this.setState({
            isCartOpen: false,
        });
    }

    handlePlzChange(e) {
        var plz = e.currentTarget.value;
        this.setState({plz: plz});
    }

    checkPlz() {
        if(this.state.plz >= 8001 && this.state.plz <= 8065 && this.state.plz != 8044 && this.state.plz != 8058)
        {
            this.setState({plzIsValidated:true});
        }
        else
        {
            this.setState({plzIsNotAllowed:true});
        }
    }

    render() {

        document.onkeydown = checkKey;

        function checkKey(e) {

            e = e || window.event;

            if (e.keyCode == '38') {
                // up arrow
            }
            else if (e.keyCode == '40') {
                // down arrow
            }
            else if (e.keyCode == '37') {
                // left arrow
                document.querySelector('.owl-prev').click();
            }
            else if (e.keyCode == '39') {
                // right arrow
                document.querySelector('.owl-next').click();
            }

            else if (e.keyCode == '13') {
                document.querySelector('.active').querySelector('.detail').click();
            }

            else if (e.keyCode == '27') {
                document.querySelector('.scroll-to-top-btn').click();
            }

        }

        console.log("lang", this.state.lang);

        let validationForm = <section className="container padding-top padding-bottom">
            <div className="row" style={{minHeight:500}}>
                <div className="col-lg-12 col-md-12 col-sm-12" style={{align:"center", margin:"auto", display:"flex", justifyContent:"center"}}>
                    <div>
                        <div>
                            {this.state.plzIsNotAllowed == true ?
                                <p style={{color:"red"}}>
                                    <FormattedMessage id={ 'message.homedelivery.check.fail' } defaultMessage={ 'Tut uns leid, Heimlieferung ist bei dir nicht verfügbar. Probiere es unter "Shop" mit einer normalen Lieferung.' }/>
                                </p> :
                                <p>
                                    <FormattedMessage id={ 'message.homedelivery.check.info' } defaultMessage={ 'Eine Heimlieferung ist nur in bestimmten Orten verfügbar:' }/>
                                </p>}
                        </div>
                        <div style={{align:"center", margin:"auto", display:"flex", justifyContent:"center"}}>
                            <input onChange={this.handlePlzChange} placeholder={this.state.lang == "de" ? "Deine Postleitzahl" : "Your postal code"}/><button onKeyPress={this.checkPlz} onClick={this.checkPlz}><FormattedMessage id={ 'message.homedelivery.check.button' } defaultMessage={ 'Check PLZ' }/></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        return (
            <div className="App">
                <Header
                    products={this.state.products}
                    checkout={this.state.checkout}
                    isCartOpen={this.state.isCartOpen}
                    handleCartClose={this.handleCartClose}
                    updateQuantityInCart={this.updateQuantityInCart}
                    removeLineItemInCart={this.removeLineItemInCart}
                />
                <section className="hero-slider" data-loop="true" data-autoplay="true" data-interval="7000">
                    {this.state.heroContent}
                </section>
                <section className="container padding-top">
                    <div style={{display: "flex"}}>
                        <div style={{marginLeft: "auto", marginRight: "auto"}}><h2><span style={{color: "orange"}}>Summer Ale
                            Aktion</span> Buy 1 Get 1 Free (24er)</h2></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginLeft:"auto", marginRight:"auto", textAlign:"center", marginTop:20, marginBottom:20}}>
                            <p>
                                <FormattedMessage id={ 'message.homedelivery.delivery1' } defaultMessage={ 'Die Lieferung erfolgt jeweils ' }/>
                                <strong><FormattedMessage id={ 'message.homedelivery.delivery2' } defaultMessage={ 'am Freitag zwischen 17 und 20 Uhr!'}/></strong><br/>
                                <FormattedMessage id={ 'message.homedelivery.delivery3' } defaultMessage={ 'Muss bis Fr. 14:00 bestellt sein und sollte persönlich empfangen werden.'}/>
                            </p>
                            {this.state.plzIsValidated == true ? <p style={{color:"green"}}><FormattedMessage id={ 'message.homedelivery.check.success' } defaultMessage={ 'Heimlieferung ist bei dir verfügbar!' }/></p> : null}
                        </div>
                    </div>
                </section>
                {this.state.plzIsValidated ?
                <ProductsHeimlieferung
                    products={this.state.products}
                    addVariantToCart={this.addVariantToCart}
                    checkout={this.state.checkout}
                    updateQuantityInCart={this.updateQuantityInCart}
                    client={client}
                /> : validationForm}
                <Footer/>
            </div>
        );
    }
}

// export default Home;
export default withRouter(Heimlieferung)
