import React, { Component } from 'react';
import Product from './Product';
import ProductFill from "./ProductFill";
import ProductHeimlieferung from "./ProductHeimlieferung";

class ProductsHeimlieferung extends Component
{
  render()
  {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

    let checkout = this.props.checkout;
    let products = this.props.products
        // TODO reactivate with tag
    // .filter(product =>
    // {
    //     // year
    //     if(parseInt(product.publishedAt.substr(0,4)) < year) return true;
    //     else if(parseInt(product.publishedAt.substr(0,4)) > year) return false;
    //
    //     // console.log("year is the same: " + parseInt(product.publishedAt.substr(0,4)) + " / " + year + " - " + product.title);
    //
    //     // month
    //     if(parseInt(product.publishedAt.substr(5,2)) < month) return true;
    //     else if(parseInt(product.publishedAt.substr(5,2)) > month) return false;
    //
    //     // console.log("month is the same: " + parseInt(product.publishedAt.substr(5,2)) + " / " + month + " - " + product.title);
    //
    //     // day
    //     if(parseInt(product.publishedAt.substr(8,2)) <= day) return true;
    //     else return false;
    // })
    .map((product) => {
      return (
        <ProductHeimlieferung
          addVariantToCart={this.props.addVariantToCart}
          key={product.id.toString()}
          product={product}
          checkout={checkout}
          client={this.props.client}
          updateQuantityInCart={this.props.updateQuantityInCart}
        />
      );
    });

    if(products.length == 0)
    {
        products = <ProductFill/>;
    }

    return (
        <section className="container padding-top padding-bottom">
          <div className="row">
            {products}
          </div>
          {/*<a href="#" className="load-more-btn space-top">Load More <sup>25</sup></a>*/}
        </section>
    );
  }
}

export default ProductsHeimlieferung;
