import React, {Component} from 'react';
import { Spinner } from 'reactstrap';

class ProductFill extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let fill = <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="shop-item">
                <div className="shop-thumbnail">
                    <a href="probier-pack" className="item-link"></a>
                    <svg style={{marginBottom:0, marginTop:0, paddingBottom:0}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 433 563"/>
                    {/*<img style={{marginBottom:-30, paddingBottom:-30}} src="/img/beer.gif"/>*/}
                    {/*<Spinner color="dark" />*/}
                    {/*<div className="shop-item-tools"></div>*/}
                </div>
                <br/>
                {/*<div className="shop-item-details">*/}
                {/*</div>*/}
            </div>
        </div>;

        return (
            <span>
                {fill}{fill}{fill}{fill}
                {fill}{fill}{fill}{fill}
                {fill}{fill}{fill}{fill}
                {fill}{fill}{fill}{fill}
                {fill}{fill}{fill}{fill}
            </span>
        );
    }
}

export default ProductFill;